import { Component } from '@angular/core';
import { ProfileService } from './core/services/profile.service';
import { UserProfile } from './core/types/userProfile';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'pls-telematics-home-marketplace';
  profile: UserProfile | undefined;

  constructor(private router: Router, private profileService: ProfileService) {
    this.profileService.profile$.subscribe((profile) => {
      this.profile = profile;
    });
  }

  ngOnInit(): void {
  }
}
