import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LightAuthService } from 'src/app/core/services/light-auth/light-auth.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.less']
})
export class AuthCallbackComponent implements OnInit {


  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    private authService: LightAuthService
  ) {

  }


  ngOnInit() {
    this.authService.processCallBack(this.router.url);

  }
}
