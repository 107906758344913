import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HowDeviceWorksComponent } from './how-device-works.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { CarouselModule } from '../carousel/carousel.module';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  declarations: [
    HowDeviceWorksComponent,
  ],
  imports: [
    CarouselModule,
    CommonModule,
    PipesModule,
  ],
  exports: [
    HowDeviceWorksComponent,
  ]
})
export class HowDeviceWorksModule { }
