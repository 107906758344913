import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';

import { Testimonial } from '../../types/testimonials';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.less']
})
export class CarouselComponent {

  @Input() data: any = [];
  @Input() title: string = '';

  activeCardIndex: number = 0;

  @ViewChildren("cardElements") cardElements!: QueryList<ElementRef>;

  constructor() {

  }

  ngOnInit() {

  }

  nextItem(): void {
    const nextItem: number =
      this.activeCardIndex < this.data.length - 1
        ? this.activeCardIndex + 1
        : this.activeCardIndex;
    this.scrollToItem(nextItem);
  }

  prevItem(): void {
    const prevItem: number =
      this.activeCardIndex > 0
        ? this.activeCardIndex - 1
        : this.activeCardIndex;
    this.scrollToItem(prevItem);
  }

  scrollToItem(index: number) {
    this.activeCardIndex = index;
    const toElement: ElementRef = this.cardElements.toArray()[index];
    if (toElement) {
      toElement.nativeElement.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'center' });
    }
  }

  carouselScroll(): void {
    for (
      let cardIndex: number = 0;
      cardIndex < this.data.length;
      cardIndex++
    ) {
      const cardElement: ElementRef = this.cardElements.toArray()[cardIndex];
      const cardRelativeX: number = cardElement.nativeElement.getBoundingClientRect().x;
      const cardWidth: number = cardElement.nativeElement.getBoundingClientRect().width;

      if (cardRelativeX < cardWidth / 2) {
        this.activeCardIndex = cardIndex;
      }
    }
  }

}
