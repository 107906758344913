import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileUpdatesComponent } from './mobile-updates.component';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  declarations: [
    MobileUpdatesComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MobileUpdatesComponent,
  ]
})
export class MobileUpdatesModule { }
