import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HeaderModule } from 'src/app/core/components/header/header.module';
import { ShippingConfirmationRoutingModule } from './shipping-confirmation-routing.module';
import { ShippingConfirmationComponent } from './shipping-confirmation.component';
import { FooterModule } from 'src/app/core/components/footer/footer.module';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    ShippingConfirmationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HeaderModule,
    FooterModule,
    ShippingConfirmationRoutingModule,
  ]
})
export class ShippingConfirmationModule { }
