<div>
    <h1>Protect the spaces in your home you're most proud of and spend the most time in.</h1>
    <p>Nationwide's smart home program not only offers a <strong>10% discount*</strong> on your policy, but <strong>free
            or discounted devices</strong> that can bring you peace of mind when it comes to the safety of your home and
        loved ones.</p>

    <app-phyn-landing></app-phyn-landing>

    <app-device-recommendations></app-device-recommendations>

    <app-smart-home-faqs [device]="'smartHome'" class="section-separator"></app-smart-home-faqs>
</div>