import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FireDevicesComponent } from './fire-devices.component';



@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    FireDevicesComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FireDevicesComponent
  ]
})
export class FireDevicesModule { }
