import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeviceFaqsRoutingModule } from './device-faqs-routing.module';
import { DeviceFaqsComponent } from './device-faqs.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HeaderModule } from 'src/app/core/components/header/header.module';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    DeviceFaqsComponent
  ],
  imports: [
    CommonModule,
    DeviceFaqsRoutingModule,
    HeaderModule
  ]
})
export class DeviceFaqsModule { }
