import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-updates',
  templateUrl: './mobile-updates.component.html',
  styleUrls: ['./mobile-updates.component.less']
})
export class MobileUpdatesComponent {
  constructor() {

  }

  ngOnInit(): void {

  }
}
