import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeviceOfferComponent } from './device-offer.component';



@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    DeviceOfferComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DeviceOfferComponent
  ]
})
export class DeviceOfferModule { }
