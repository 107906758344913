import { Component, Input } from '@angular/core';

import { DeviceInfo, DeviceStaticInfo } from '../../types/deviceInfo';
import { VendorName } from '../../types/device';

@Component({
  selector: 'app-device-education',
  templateUrl: './device-education.component.html',
  styleUrls: ['./device-education.component.less']
})
export class DeviceEducationComponent {

  // Inputs/Outputs
  @Input() device: VendorName = VendorName.LEAKBOT;

  // Component-level variables
  deviceInfo: DeviceInfo = {} as DeviceInfo;

  constructor() { }

  ngOnInit() {
    if (this.device) {
      this.deviceInfo = DeviceStaticInfo[this.device];
    }
  }

  shouldDisplayComponent(deviceType: string): boolean {
    return this.deviceInfo.deviceType === deviceType;
  }

}
