<app-header [showMenu]="false"></app-header>
<bolt-waiting-indicator id="fullscreenWaitDemo" fullscreen *ngIf="authenticating">Signing you
    in...</bolt-waiting-indicator>

<div class="bolt-container">
    <form #authForm (keydown)="keyPressed($event);">
        <div class="bolt-row">
            <div class="bolt-col">
                <div *ngIf="authSuccess === false">Authentication failed - make sure last name and zip are
                    correct</div>
            </div>
        </div>


        <div class="bolt-row my-5">
            <div class="bolt-col-sm-4">
                <bolt-textfield label="Last Name" [formControl]="lastName" ngDefaultControl required
                    [error]="lastNameError">
                </bolt-textfield>
            </div>
        </div>

        <div class="bolt-row mb-5 pb-2">
            <div class="bolt-col-sm-4">
                <bolt-textfield label="ZIP Code" [formControl]="zip" ngDefaultControl required
                    [error]="zipError"></bolt-textfield>
            </div>
        </div>

        <div class="bolt-row mb-5">
            <div class="bolt-col-sm-4">
                <bolt-button type="primary" (click)="authenticate();" width="full">Submit</bolt-button>
            </div>
        </div>
    </form>
</div>


<div *ngIf="loadIframe">
    <iframe [src]="iframeUrl" class="hidden" title="quick auth"></iframe>
</div>