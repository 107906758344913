import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceEducationComponent } from './device-education.component';
import { WaterDevicesModule } from './water-devices/water-devices.module';
import { FireDevicesModule } from './fire-devices/fire-devices.module';



@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    DeviceEducationComponent
  ],
  imports: [
    CommonModule,
    WaterDevicesModule,
    FireDevicesModule
  ],
  exports: [
    DeviceEducationComponent
  ]
})
export class DeviceEducationModule { }
