<div class="flex flex-col h-full">
  <div class="bolt-container flex-1 flex flex-col justify-content-center">

    <div class="bolt-row pt-10 pb-5">
      <div class="bolt-col text-center">
        <img src='../../../../../assets/images/error-bubbles.svg' alt="">
      </div>
    </div>

    <div class="bolt-row">
      <div class="bolt-col text-center">
        <span class="blue-font-color heading-text">{{ messaging.title}}</span>
      </div>
    </div>

    <div class="bolt-row pt-3">
      <div class="bolt-col text-center">
        <span>{{ messaging.description }}</span>
      </div>
    </div>

    <div class="bolt-row pt-10">
      <div class="bolt-col text-center">
        <span><b>Questions?</b></span>
      </div>
    </div>

    <div class="bolt-row pt-2 pb-10">
      <div class="bolt-col text-center">
        <span>Contact <a href="https://www.nationwide.com/personal/contact/insurance/">Nationwide</a></span>
      </div>
    </div>

  </div>


  <div class="bolt-footer background-accent pin-to-bot">
    <div class="bolt-container">
      <div class="bolt-footer__head">
        <div class="bolt-footer__intro">
          <div class="bolt-footer__logo">
            <bolt-logo href="/landing?dataCollectionId={{dataCollectionId}}" type="nw-vertical"></bolt-logo>
          </div>
          <div class="bolt-footer__phone">
            <span class="bolt-footer__phone__alpha color-black">
              1-877 On Your Side
            </span>
            <span class="color-black">(1-877-669-6877)</span>
          </div>
        </div>
      </div>
      <div class="bolt-footer__body">
        <div class="bolt-footer__content color-black text-center">
          <p>
            Nationwide Mutual Insurance Company and affiliates. Columbus, OH. Nationwide, the Nationwide N and Eagle
            and
            other marks
            are service marks of Nationwide Mutual Insurance Company, unless otherwise disclosed. ©2023
          </p>
          <p>
            At Nationwide, we work hard to ensure your online safety, security, and privacy. Please take a moment to
            review our
            policies. To remove your email address from this or all Nationwide email lists, please go <a
              href="https://www.nationwide.com/personal/privacy-security/" target="_blank"
              rel="noopener noreferrer">here</a> and
            follow
            instructions.
          </p>
        </div>
      </div>
    </div>
  </div>

</div>