<div class="font-size-lg text-center pb-2">
    {{title}}
</div>

<div class="carousel-container">
    <div class="relative">
        <div class="carousel-arrows-container">
            <div class="carousel-arrow-container">
                <div *ngIf="activeCardIndex != 0" class="carousel-left-arrow carousel-arrow unselectable"
                    (click)="prevItem()">
                    <bolt-icon name="chevron-left" color="vibrant-blue"></bolt-icon>
                </div>
            </div>

            <div class="carousel-arrow-container">
                <div *ngIf="activeCardIndex != data.length - 1" class="carousel-right-arrow carousel-arrow unselectable"
                    (click)="nextItem()">
                    <bolt-icon name="chevron-right" color="vibrant-blue"></bolt-icon>
                </div>
            </div>
        </div>

        <div class="carousel-scroll-container" (scroll)="carouselScroll()">
            <div #cardElements *ngFor="let element of data; let idx = index" id="testimonial{{ idx }}"
                class="testimonial" [ngClass]="element.pathToImg ? 'card-bg-white' : ''">
                <div class="bolt-row bolt-align-items-center">
                    <div class="bolt-col">
                        <div *ngIf="element.pathToImg" class="text-center pb-3">
                            <img alt="" [src]="element.pathToImg">
                        </div>
                        <div *ngIf="element.name">
                            <div class="font-color-darker-crop-blue pb-3 text-center font-size-normal">
                                <strong>{{element.name}}</strong>
                            </div>
                            <div class="pb-5 font-size-sm ml-10 mr-10 line-height-tight text-center">
                                <i><span>{{element.quote}}</span></i>
                            </div>
                        </div>
                        <div *ngIf="element.title">
                            <div class="font-color-darker-crop-blue pb-3 text-center font-size-normal">
                                <strong>{{element.title}}</strong>
                            </div>
                            <div class="font-size-sm ml-10 mr-10 line-height-tight text-center">
                                {{element.description}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="carousel-dots-container">
        <div *ngFor="let element of data; let idx = index" (click)="scrollToItem(idx)">
            <div *ngIf="activeCardIndex == idx" class="carousel-full-dot carousel-dot"></div>
            <div *ngIf="activeCardIndex != idx" class="carousel-empty-dot carousel-dot"></div>
        </div>
    </div>
</div>