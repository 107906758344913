export interface OidcConfiguration {
    authority: string;
    authMethod: string;
    realm: string;
    ttl: number;
}

export interface Configuration {
    secrets: {
        smartHomeMarket: {
            clientId: string;
            apigeeHostUrl: string;
            env: string;
        },
        authorize: {
            oidc: OidcConfiguration;
            permissionGroups: PermissionGroups
        },
        campaigns?: Record<string, Campaign>;
    }
}

export interface Campaign {
    url: string;
    name: string;
}

export interface PermissionGroups {
    permittedAdminGroups: string[];
    permittedProviderSearchGroups: string[];
}

export enum PermissionGroup {
    permittedAdminGroups = "permittedAdminGroups",
    permittedProviderSearchGroups = "permittedProviderSearchGroups",
}