import { Injectable } from '@angular/core';

import { Device } from '../../types/device';

@Injectable({
  providedIn: 'root'
})
export class DeviceHelpersService {

  constructor() { }

  getCurrentDevice(devices: Device[], deviceShortName: string): Device {
    let foundDevice: Device | undefined = devices.find((device: Device) => {
      return device.shortName === deviceShortName;
    })
    // TODO: Change the negative condition when we implement error handling
    return foundDevice ? foundDevice : {} as Device;
  }

  getTotalDeviceCost(device: Device): number {
    return device.cost - (device.cost * (device.discount / 100));
  }

  getSelectedDevices(devices: Device[] | undefined): Device[] {
    if (!devices) {
      return [];
    }
    return devices.filter((device: Device) => {
      return device.selected;
    });
  }
}
