import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthCallbackModule } from './pages/quick-authentication/auth-callback/auth-callback.module';
import { HeaderModule } from './core/components/header/header.module';
import { DeviceLandingModule } from './pages/device-landing/device-landing.module';
import { PipesModule } from './pipes/pipes.module';
import { RecommendationsModule } from './pages/recommendations/recommendations.module';
import { SmartHomeHelpModule } from './pages/help/smarthome-help.module';
import { QuickAuthenticationModule } from './pages/quick-authentication/quick-authentication.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './interceptors/header-interceptor';
import { ConfigLoaderService } from './core/services/config-loader/config-loader.service';
import { Observable } from 'rxjs';
import { AuthConfigModule } from './auth/auth-config.module';

const loadAppConfiguration = (configLoaderService: ConfigLoaderService) => {
  return (): Observable<any> => {
    return configLoaderService.loadAppConfig();
  };
};

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Added for Bolt
  ],
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    AuthCallbackModule,
    BrowserModule,
    HeaderModule,
    DeviceLandingModule,
    PipesModule,
    QuickAuthenticationModule,
    RecommendationsModule,
    SmartHomeHelpModule,
    HttpClientModule,
    AuthConfigModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigLoaderService],
      useFactory: loadAppConfiguration,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
