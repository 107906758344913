import { Injectable } from '@angular/core';
import { StsConfigStaticLoader, LogLevel } from 'angular-auth-oidc-client';
import { ConfigLoaderService } from '../config-loader/config-loader.service';
import { PermissionGroup } from '../../types/configuration';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeAdminService {

  constructor(private readonly config: ConfigLoaderService) { }

  createConfigLoader(): StsConfigStaticLoader {
    return new StsConfigStaticLoader({
      authority: this.config.app.secrets.authorize.oidc.authority,
      redirectUrl: `${window.location.origin}/admin`,
      postLogoutRedirectUri: `${window.location.origin}/admin`,
      clientId: this.config.app.secrets.smartHomeMarket.clientId,
      scope: 'openid',
      responseType: 'id_token token',
      silentRenew: true,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      triggerAuthorizationResultEvent: true,
      startCheckSession: true,
      logLevel: LogLevel.None,
      customParamsAuthRequest: {
        auth_method: this.config.app.secrets.authorize.oidc.authMethod,
        realm: this.config.app.secrets.authorize.oidc.realm,
        ttl: this.config.app.secrets.authorize.oidc.ttl,
      },
      postLoginRoute: '/admin',
      unauthorizedRoute: '/error',
    });
  }

  hasPermittedGroup(groups: string[], permittedGroups: PermissionGroup): boolean {
    return this.config.app.secrets.authorize.permissionGroups[permittedGroups].some(
      (authorizedGroup: string): boolean =>
        groups.some((group: string): boolean => group === authorizedGroup)
    );
  }
}
