import { Component } from '@angular/core';

import { ProfileService } from '../../services/profile.service';
import { Survey } from '../../types/userProfile';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.less']
})
export class SurveyComponent {

  survey: Survey[] = [];

  constructor(
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.profileService.profile$.subscribe((profile) => {
      if (profile?.survey) {
        this.survey = profile.survey;
      }
    });
  }

}
