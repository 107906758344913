import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderModule } from 'src/app/core/components/header/header.module';
import { OrderConfirmationRoutingModule } from './order-confirmation-routing.module';
import { OrderConfirmationComponent } from './order-confirmation.component';
import { SurveyModule } from 'src/app/core/components/survey/survey.module';
import { MobileUpdatesModule } from 'src/app/core/components/mobile-updates/mobile-updates.module';
import { FooterModule } from 'src/app/core/components/footer/footer.module';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    OrderConfirmationComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    OrderConfirmationRoutingModule,
    SurveyModule,
    MobileUpdatesModule
  ]
})
export class OrderConfirmationModule { }
