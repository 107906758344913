import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShippingConfirmationComponent } from './shipping-confirmation.component';

const routes: Routes = [
  { path: '**', component: ShippingConfirmationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShippingConfirmationRoutingModule { }
