import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SmartHomeProduct } from 'src/app/core/types/product';
import { UserProfile } from 'src/app/core/types/userProfile';

@Component({
  selector: 'smarthome-help',
  templateUrl: './smarthome-help.component.html',
  styleUrls: ['./smarthome-help.component.less']
})
export class SmartHomeHelpComponent implements OnInit {

  public SmartHomeProduct = SmartHomeProduct;
  profile: UserProfile = {} as UserProfile;



  // These two lines are deletable code
  products: string[] = []; // delete Me
  count: number = 0; // delete Me

  constructor(
    private router: Router,
    private profileService: ProfileService) {
    this.profileService.profile$.subscribe((profile) => {
      this.profile = profile;
    });
  }

  ngOnInit(): void {
    // This is all deletable code proving that profile will update accordingly
    for (const product in SmartHomeProduct) {
      this.products.push(product);
    }
  }

  // This is just for testing, delete for sure
  updateCurrentDevice(): void {
    if (this.profile) {
      this.profile.currentDevice = this.products[this.count];
    }
    this.count += 1;
    if (this.count > this.products.length - 1) {
      this.count = 0
    }
  }

  navToDeviceLandingPage(): void {
    this.router.navigateByUrl(`/${this.profile.currentDevice}`);
  }
}
