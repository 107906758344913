import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdditionalOrderInfoRoutingModule } from './additional-order-info-routing.module';
import { AdditionalOrderInfoComponent } from './additional-order-info.component';
import { HeaderModule } from 'src/app/core/components/header/header.module';
import { FooterModule } from 'src/app/core/components/footer/footer.module';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AdditionalOrderInfoComponent,
  ],
  imports: [
    AdditionalOrderInfoRoutingModule,
    CommonModule,
    HeaderModule,
    FooterModule,
  ]
})
export class AdditionalOrderInfoModule { }
