import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartHomeFaqsComponent } from './smart-home-faqs.component';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    SmartHomeFaqsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SmartHomeFaqsComponent
  ]
})
export class SmartHomeFaqsModule { }
