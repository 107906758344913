<div>
    <div class="bolt-container">
        <div class="bolt-row">
            <div class="bolt-col-6">
                <img src="../../../../assets/images/LeakBot-marketing.png" alt="">
            </div>
            <div class="bolt-col-6">
                <div class="bolt-row">
                    {{ device.fullName }}
                </div>
                <div class="bolt-row">
                    <!-- Add spacing between the cells-->
                    <div class="bolt-col">
                        <strong>{{ getTotalDeviceCost() | currency }}</strong>
                    </div>
                    <div class="bolt-col">
                        <s>{{ device.cost | currency }}</s>
                    </div>
                    <div class="bolt-col">
                        Save {{ device.discount }}%
                    </div>
                </div>
                <div class="bolt-row">
                    {{ device.description }}
                </div>
                <!-- Pin the link to the bottom of the component-->
                <div class="bolt-row">
                    <a (click)="navigateToDeviceLandingPage(device)">Learn more about {{ device.vendorName }}</a>
                </div>
            </div>
        </div>
    </div>
</div>