import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlSanitizePipe } from './url-sanitize/url-sanitize.pipe';
import { SentenceCasePipe } from './sentence-case/sentence-case.pipe';



@NgModule({
    declarations: [
        UrlSanitizePipe,
        SentenceCasePipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        UrlSanitizePipe,
        SentenceCasePipe
    ]
})
export class PipesModule { }
