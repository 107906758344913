import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LightAuthService } from 'src/app/core/services/light-auth/light-auth.service';
import { QueryParameters, UserPrivilege } from 'src/app/core/constants';
import { ProgramService } from 'src/app/core/services/program/program.service';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { HttpError } from 'src/app/core/types/http/error';
import { ProfileService } from 'src/app/core/services/profile.service';
import { UserProfile } from 'src/app/core/types/userProfile';

@Component({
  selector: 'app-quick-authentication',
  templateUrl: './quick-authentication.component.html',
  styleUrls: ['./quick-authentication.component.less']
})
export class QuickAuthenticationComponent implements OnInit, OnDestroy {

  iframeUrl: SafeResourceUrl = '';
  loadIframe: boolean = false;
  listener: any = undefined;
  authSuccess: boolean | undefined = undefined;
  authenticating: boolean = false;
  profile: UserProfile | undefined;

  lastName: FormControl = new FormControl('', Validators.compose([
    Validators.required,
  ]));
  zip: FormControl = new FormControl('', Validators.compose([
    Validators.required,
    Validators.pattern('^[0-9]{5}$')
  ]));
  dataCollectionId!: string;
  policyNumber: string = '';

  lastNameError: string = '';
  policyNumberError: string = '';
  zipError: string = '';

  FIELD_REQUIRED: string = 'This field is required.';
  INVALID_FIELD: string = 'This field is invalid.';

  constructor(
    private renderer: Renderer2,
    private authService: LightAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private programService: ProgramService,
    private navService: NavigationService,
    private profileService: ProfileService,
    private sanitizer: DomSanitizer,
  ) {

  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has(QueryParameters.DataCollectionId)) {
        this.dataCollectionId = queryParams.get(QueryParameters.DataCollectionId) ?? '';
      }
    });

    this.profileService.profile$.subscribe((profile: UserProfile | undefined) => {
      this.profile = profile;
    });
  }

  showErrors(field: FormControl): string {
    let error: string = '';
    if (field.errors) {
      if (field.errors['required']) {
        error = this.FIELD_REQUIRED;
      } else if (field.errors['pattern']) {
        error = this.INVALID_FIELD;
      }
    }
    return error;
  }

  receiveMessage(event: any): void {
    if (event.data !== 'auth_success' && event.data !== 'auth_failed') {
      return;
    }

    this.authenticating = false;
    this.authSuccess = event.data === 'auth_success' ? true : false;
    this.loadIframe = false;
    this.listener();
  }

  keyPressed(event: any): void {
    if (event.keyCode === 13) {
      this.authenticate();
    }
  }

  authenticate(): void {
    this.programService.getPolicyNumber(this.dataCollectionId).then((policyData) => {
      this.policyNumber = policyData.policyNumber;
      if (this.profile) {
        this.profile.policyNumber = policyData.policyNumber;
        this.profile.policyType = policyData.policyType;
      }

      this.lastNameError = this.showErrors(this.lastName);
      this.zipError = this.showErrors(this.zip);

      this.authenticating = true;
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.authService.buildAuthCustomerSearchUrl(this.lastName.value, this.policyNumber, this.zip.value));
      this.loadIframe = true;
      this.listener = this.renderer.listen('window', 'message', (event) => {
        this.receiveMessage(event);
        if (this.authSuccess) {
          if (this.profile) {
            this.profile.privilegeLevel = UserPrivilege.User;
            this.profile.customer.lastName = this.lastName.value
          }
          this.route.queryParams.subscribe((params: any) => {
            this.router.navigate(['/landing'], { queryParams: params })
          })
        }
      });
      console.info('Quick Login Page Continue Click');
    }).catch((err: HttpError) => {
      this.navService.redirectError(err)
    });
  }


  ngOnDestroy(): void {
  }

}
