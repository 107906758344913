export interface Testimonial {
    quote: string;
    name: string;
    date: string;
}

export const LeakbotTestimonials: Testimonial[] = [
    {
        quote:
            `"LeakBot had detected we had a small leak. The people at LeakBot rang me and asked if I wanted someone to come and check for a leak. Ian came to our house promptly. He was friendly and knowledgeable, and his tests all took about 20 minutes. He concluded there was no leak and the warning may have been due to us starting to water a newly laid lawn recently."`,
        name: 'Peace of mind',
        date: 'Feb 3, 2022',
    },
    {
        quote:
            `"Instantly found I had a water leak, engineer here next day and within 30 minutes had identified the problem."`,
        name: 'Instantly found I had a water leak',
        date: 'Feb 4, 2022',
    },
    {
        quote:
            `"Following a LeakBot notification of a leak the engineer found that my cold water tank in the attic was weeping, if this hadn't been found I would eventually have had 50 gallons of water running through my ceiling down into the rooms below."`,
        name: 'Major catastrophe averted',
        date: 'Mar 9, 2022',
    },
    {
        quote:
            `"What an amazing device. My wife left the outside tap dripping. Shortly after, I got an alert from Leakbot advising me of a possible leak. Checked taps, found dripping tap and turned it off. Fantastic... If that had been a leak in the system, it would have alerted me immediately possibly saving serious water damage. Fantastic device..."`,
        name: 'What an amazing device',
        date: 'December 4, 2021',
    },
];

export const PhynTestimonials: Testimonial[] = [
    {
        quote:
            `"Great system. Great to have the peace of mind, particularly when traveling.User interface is good, and the system learns water use patterns easily."`,
        name: 'Anonymous, Washington',
        date: 'Feb 3, 2022',
    },
    {
        quote:
            `"Great investment for peace of mind. It is very reassuring to know the water will be turned off in the event of a leak or pipe burst, that could cause a tremendous amount of damage to my home and neighbors, this is a great investment"`,
        name: 'Anonymous, Florida',
        date: 'Feb 4, 2022',
    },
    {
        quote:
            `"Detected a small leak in my water heater...I thought it was detecting a false drop, turns out my water heater had a sneaky leak only to be discovered later! Besides that, kind of protection, the usage monitoring, ability to remotely shut off the water, and the peace of mind are worth the investment."`,
        name: 'Anonymous, Florida',
        date: 'Mar 9, 2022',
    },
    {
        quote:
            `"Does exactly what it claims! The Phyn Plus Smart Water Assistant does exactly what it claims it will do. Once set up it is very intuitive and has given us the peace of mind we were hoping for. There have been a few occasions that the system has notified us of an unusual event and provided us with the opportunity to respond to it. Overall, we are extremely happy with the system and the Phyn App, which is easy to use and provides helpful information. All in all, we love the Phyn Plus system!"`,
        name: 'Anonymous, California',
        date: 'December 4, 2021',
    },
];

export const TingTestimonials: Testimonial[] = [
    {
        quote:
            `"We had a washer and dryer burn out. We thought it was wiring in the house. Turns out it was a transformer on the utility pole outside our house. Thanks so much to Ting"`,
        name: 'Bryan, Michigan',
        date: 'Feb 3, 2022',
    },
    {
        quote:
            `"My lights no longer flicker when the furnace comes on and the volts to my home are a steady 120ish. I am sure those power surges were probably going to get dangerous at some point. Thank you so much!"`,
        name: 'Laurie, Iowa',
        date: 'Feb 4, 2022',
    },
    {
        quote:
            `"While I'm aware fires may not be 100% preventable, being as safe as possible helps give some peace of mind. That being said, Ting can do things a human cannot."`,
        name: 'Brandon, Arkansas',
        date: 'Mar 9, 2022',
    },
    {
        quote:
            `"We thought our microwave was dying, but it was something much more dangerous. You saved us!"`,
        name: 'Laurie M, California',
        date: 'December 4, 2021',
    },
];

export const NotionTestimonials: Testimonial[] = [];