import { Location } from '@angular/common'
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceStaticInfo, QA } from 'src/app/core/types/deviceInfo';

@Component({
  selector: 'app-device-faqs',
  templateUrl: './device-faqs.component.html',
  styleUrls: ['./device-faqs.component.less']
})
export class DeviceFaqsComponent {
  faqs: QA[] = [];

  constructor(private route: ActivatedRoute, private location: Location) { }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.faqs = DeviceStaticInfo[params["device"] as keyof typeof DeviceStaticInfo].faqs;
    });
  }

  back(): void {
    this.location.back();
  }
}
