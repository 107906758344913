import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

import { lastValueFrom } from 'rxjs';
import { PolicyDetails } from '../../types/http/policyDetails';
import { ProgramDetails } from '../../types/http/programDetails';
import { DeviceCategories } from '../../types/http/deviceCategories';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  constructor(private api: ApiService) { }

  async getPolicyNumber(dataCollectionId: string): Promise<PolicyDetails> {
    return await lastValueFrom(this.api.apigeeGet(`programs/${dataCollectionId}`)).catch((err) => { throw err });
  }

  async getProgramDetails(policyNumber: string): Promise<ProgramDetails> {
    return await lastValueFrom(this.api.apigeeGet(`policy/${policyNumber}`)).catch((err) => { throw err });
  }

  async getDiscountCategories(policyNumber: string): Promise<DeviceCategories> {
    return await lastValueFrom(this.api.apigeeGet(`policy-number/${policyNumber}/discount-categories`)).catch((err) => { throw err });
  }
}
