import { Component, ElementRef, Input } from '@angular/core';

import { Device } from '../../types/device';
import { DeviceHelpersService } from '../../services/helpers/device-helpers.service';
import { DeviceInfo, DeviceStaticInfo } from '../../types/deviceInfo';

@Component({
  selector: 'app-device-offer',
  templateUrl: './device-offer.component.html',
  styleUrls: ['./device-offer.component.less']
})
export class DeviceOfferComponent {

  @Input() device: Device = Input();
  @Input() howItWorksElement: ElementRef = Input();

  deviceInfo: DeviceInfo = {} as DeviceInfo;

  constructor(
    private deviceHelperService: DeviceHelpersService
  ) { }

  ngOnInit() {
    this.deviceInfo = DeviceStaticInfo[this.device.shortName];
  }

  getTotalDeviceCost(device: Device): number {
    return this.deviceHelperService.getTotalDeviceCost(device);
  }

  moveToHowItWorks(): void {
    this.howItWorksElement?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
