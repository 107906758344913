<div class="bolt-container">
  <div class="bolt-row pb-6">
    <div class="bolt-col">
      <bolt-tabset>
        <bolt-tablabel class="text-center"><b><span>Why electrical <br> fire
              devices?</span></b></bolt-tablabel>
        <bolt-tabpanel>
          <div class="bolt-row bolt-align-items-center mb-3">
            <div class="bolt-col-4 pl-0 pr-0 text-center">
              <img src='../../../../../assets/images/education/electrical-hazard.svg' alt="">
            </div>
            <div class="bolt-col">
              <span>
                Electrical systems are the
                third leading cause of home structure fires.<sup>1</sup>
              </span>
            </div>
          </div>
          <div class="bolt-row bolt-align-items-center">
            <div class="bolt-col">
              <span>Common warning signs that can go undetected:</span>
              <ul class="list-spacing">
                <li>Circuit breaker keeps tripping</li>
                <li>Persisting burnt smell with no identifiable source</li>
                <li>Several discolored or charred outlets and switches</li>
                <li>You have old, outdated wiring<sup>2</sup></li>
              </ul>
            </div>
            <div class="bolt-col-4 pl-0 pr-0 text-center">
              <img src='../../../../../assets/images/education/plug-fire.svg' alt="">
            </div>
          </div>
        </bolt-tabpanel>

        <bolt-tablabel class="text-center"><b><span>What they <br> do for <br> your
              home</span></b></bolt-tablabel>
        <bolt-tabpanel>
          <div class="bolt-row bolt-align-items-center mb-3">
            <div class="bolt-col-4 pl-0 pr-0 text-center">
              <img src='../../../../../assets/images/education/electrical-hazard.svg' alt="">
            </div>
            <div class="bolt-col">
              <span>
                Monitor your home's electrical network & utility grid for issues that could lead to
                electrical fires
              </span>
            </div>
          </div>
          <div class="bolt-row bolt-align-items-center mb-3">
            <div class="bolt-col-4 pl-0 pr-0 text-center">
              <img src='../../../../../assets/images/education/fire-notification.svg' alt="">
            </div>
            <div class="bolt-col">
              <span>
                Notify you if a fire hazard is detected
              </span>
            </div>
          </div>
          <div class="bolt-row bolt-align-items-center">
            <div class="bolt-col-4 pl-0 pr-0 text-center">
              <img src='../../../../../assets/images/education/home-fire.svg' alt="">
            </div>
            <div class="bolt-col">
              <span>
                Help prevent the inconvenience of filing a claim, saving you time & protecting what
                matters most in your home
              </span>
            </div>
          </div>
        </bolt-tabpanel>
      </bolt-tabset>
    </div>
  </div>
</div>