import { Component } from '@angular/core';

@Component({
  selector: 'app-fire-devices',
  templateUrl: './fire-devices.component.html',
  styleUrls: ['./fire-devices.component.less']
})
export class FireDevicesComponent {

}
