<div class="bolt-row pt-8">
    <div class="bolt-col text-center no-side-pad">
        <span class="font-size-lg"><strong>Your Nationwide member offer</strong></span>
    </div>
</div>

<div class="bolt-row pt-5">
    <div class="bolt-col text-center">
        <img *ngIf="deviceInfo.offerImagePath" [src]="deviceInfo.offerImagePath" alt="Device Image" width="100%"
            class="fluid-height">
    </div>
</div>

<div class="bolt-container device-offer-styles">
    <div class="bolt-row">
        <div class="bolt-col pt-3 text-center">
            <span class="font-size-xl"><strong>{{deviceInfo.vendorTitle}}</strong></span>
        </div>
    </div>

    <div class="bolt-row pt-3 bolt-align-items-center">
        <div class="bolt-col text-center">
            <span class="font-size-normal">{{getTotalDeviceCost(device) | currency}}</span>
            &nbsp;&nbsp;
            <!-- <span class="font-size-normal text-dark-grey"><s>{{device.cost | currency}}</s></span>
            &nbsp;&nbsp; -->
            <span class="font-size-normal text-dark-grey">Save {{device.discount}}%</span>
        </div>
    </div>

    <div class="bolt-row pt-3" *ngIf="deviceInfo?.offerDescription">
        <div class="bolt-col text-center">
            <span class="font-size-sm">{{deviceInfo.offerDescription}}</span>
        </div>
    </div>

    <div class="bolt-row pt-3">
        <div class="bolt-col-1 bolt-offset-2 info-disclaimer">
            <img src="../../../../../../assets/images/icon-infoCircle.svg" alt="Info Circle">
        </div>
        <div class="bolt-col info-disclaimer-text">
            <span class="text-dark-grey font-size-xs">
                <i>&nbsp;A smartphone and Wi-Fi connection with 24/7 internet access are
                    required.</i>
            </span>
        </div>
    </div>

    <div class="bolt-row pt-3 pb-8">
        <div *ngIf="deviceInfo.offerImagePath" class="bolt-col text-center">
            <a href="javascript:void(0)" (click)="moveToHowItWorks()" class="hyper-blue">Learn
                more about How It
                Works</a>
        </div>
    </div>
</div>