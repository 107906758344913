import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigLoaderService } from "../core/services/config-loader/config-loader.service";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  private clientId = '';
  private urlMessageIDHeadersWithoutDash = ['discount-categories'];

  constructor(private configService: ConfigLoaderService) {
    this.configService.appConfig$.subscribe((appConfig: any) => {
      if (appConfig?.secrets?.smartHomeMarket) {
        this.clientId = appConfig.secrets.smartHomeMarket.clientId ?? 'empty';
      }
    })
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('/userinfo')
      && !req.url.includes('/.well-known/openid-configuration')
      && !req.url.includes('/security/publicKeys/v1/keys')) {
      // TODO: Work with SHP to fix this in the future
      // const newHeaders = {
      //   'X-NW-Message-ID': this.generateMessageId(),
      //   'client_id': this.clientId,
      // };
      const newHeaders = {
        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`,
        'client_id': this.clientId, ...this.messageIdHeader(req.url)
      };

      return next.handle(req.clone({ setHeaders: newHeaders }));
    }

    return next.handle(req);
  }

  private generateMessageId(): string {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }

  private messageIdHeader(url: string): any {
    // TODO: Work with SHP to fix this in the future
    // Should not need to send BOTH X-NW-Message-ID AND X-NW-MessageID in the same set of headers. This is incorrect
    return this.urlMessageIDHeadersWithoutDash.find((urlWithoutDash: string) => { return url.includes(urlWithoutDash) })
      ? { 'X-NW-MessageID': this.generateMessageId(), 'X-NW-Message-ID': this.generateMessageId() } : { 'X-NW-Message-ID': this.generateMessageId() };
  }
}