import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { ConfigLoaderService } from '../config-loader/config-loader.service';

@Injectable({
  providedIn: 'root'
})
export class LightAuthService {
  // for calling identity
  apigeeHostUrl: string;
  // baseUrl: string;
  clientId: string;
  nounce: string;
  redirectUrl: string;
  responseType: string;
  scope: string;
  realm: string;
  authMethod: string;
  ttl: number;
  jwtDecode = jwtDecode;

  // for accessing services
  accessToken: string = '';
  decodedIdentityToken: any = {};


  constructor(private config: ConfigLoaderService) {
    this.apigeeHostUrl = this.config.app.secrets.authorize.oidc.authority;
    this.clientId = this.config.app.secrets.smartHomeMarket.clientId;
    this.nounce = 'myNonce';
    this.redirectUrl = window.location.origin + "/auth-callback";
    this.responseType = 'token';
    this.scope = 'openid';
    this.realm = 'unidentified';
    this.authMethod = 'customer-search';
    this.ttl = 1200;
  }

  buildAuthCustomerSearchUrl(lastName: string, policyNumber: string, zip: string): string {
    return `${this.apigeeHostUrl}?client_id=${this.clientId}&nonce=${this.nounce}&redirect_uri=${this.redirectUrl}&response_type=${this.responseType}&scope=${this.scope}&realm=${this.realm}&auth_method=${this.authMethod}&auth_id_lastName=${encodeURIComponent(lastName)}&auth_id_postalCode=${encodeURIComponent(zip)}&auth_id_agreementNumber=${encodeURIComponent(policyNumber)}&state=request-type-create-session--smart-home`
  }


  processCallBack(urlFragment: string): any {

    const authType = window.sessionStorage.getItem('tokenDetails') ? 'refresh' : 'login';

    if (urlFragment.includes('&') && urlFragment.includes('=')) {
      // Convert to list of parameters to easily parse
      const tokenFragment = urlFragment.split('#')[1];
      const parameterString = tokenFragment.split('&').map(pair => {
        const [name, value] = pair.split('=');
        window.sessionStorage.setItem(name, value); // store in session
        return {
          name: decodeURIComponent(name),
          value: decodeURIComponent(value)
        };
      });

      // Determine if success of failure
      if (parameterString.some(param => param.name === 'access_token')) {
        // now calculate the time in ms token expires
        window.sessionStorage.setItem('expires_at', (Date.now() + (parseInt(parameterString.find(param => param.name === 'expires_in')!.value) * 1000)).toString()); // store in session

        // pull out details
        this.accessToken = parameterString.find(param => param.name === 'access_token')!.value;

        sessionStorage.setItem('access_token', this.accessToken);
        parent.postMessage(`auth_success`, window.location.origin);
      } {
        // Failure
        parent.postMessage(`auth_failed`, window.location.origin);
      }

      return false;
    }
  }

}
