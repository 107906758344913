<div class="bolt-container" id="whys">
  <div class="bolt-row pb-6">
    <div class="bolt-col">
      <bolt-tabset>
        <bolt-tablabel class="text-center flex-1 bolt-tab-text-wrap">
          <div class="bold flex align-items-center flex-1 h-100 justify-content-center">
            Why water leak detection devices?
          </div>
        </bolt-tablabel>
        <bolt-tabpanel>
          <div class="bolt-row bolt-align-items-center mb-3 flex-no-wrap">
            <div class=" pl-0 pr-0 text-center">
              <img src='../../../../../assets/images/education/plumbing-money.svg' alt="">
            </div>
            <div class="flex-1 p-2 ml-3 mr-2 rounded-border-10 education-gray-bg text-center">
              <span>
                Americans waste roughly
                1 trillion gallons of water yearly due to leaky pipes, faucets, sprinkler systems, and
                more.<sup>1</sup>
              </span>
            </div>
          </div>
          <div class="bolt-row bolt-align-items-center flex-no-wrap">
            <div class="flex-1 p-2 mr-3 ml-2 rounded-border-10 education-blue-bg text-center">
              <span>
                On average, water damage repairs cost $11,600, and
                that's not including the loss
                of irreplaceable items and the accompanying emotional toll.<sup>2</sup>
              </span>
            </div>
            <div class="pl-0 pr-0 text-center">
              <img src='../../../../../assets/images/education/tub-leak.svg' alt="">
            </div>
          </div>
        </bolt-tabpanel>

        <bolt-tablabel class="text-center flex-1 bolt-tab-text-wrap">
          <div class="bold flex align-items-center flex-1 h-100 justify-content-center">
            What they do for your home?
          </div>
        </bolt-tablabel>
        <bolt-tabpanel>
          <div class="bolt-row bolt-align-items-center mb-3 flex-no-wrap justify-content-start">
            <div class="pl-0 pr-0 text-center">
              <img src='../../../../../assets/images/education/peace-of-mind.svg' alt="">
            </div>
            <div class="p-2 ml-3 mr-2 rounded-border-10">
              <span>
                Give you peace of mind by monitoring and preventing major water damage within the home
              </span>
            </div>
          </div>
          <div class="bolt-row bolt-align-items-center mb-3 flex-no-wrap justify-content-end">
            <div class="p-2 mr-3 ml-2 rounded-border-10">
              <span>
                Detect & notify you of invisible water leaks that may be occurring in the home
              </span>
            </div>
            <div class="pl-0 pr-0 text-center">
              <img src='../../../../../assets/images/education/leak-warning.svg' alt="">
            </div>
          </div>
          <div class="bolt-row bolt-align-items-center flex-no-wrap justify-content-start">
            <div class="pl-0 pr-0 text-center">
              <img src='../../../../../assets/images/education/leak-save-money.svg' alt="">
            </div>
            <div class="p-2 ml-3 mr-2 rounded-border-10 education-blue-bg">
              <span>
                Help prevent the inconvenience of filing a claim, saving you time & protecting what
                matters most in your home
              </span>
            </div>
          </div>
        </bolt-tabpanel>
      </bolt-tabset>
    </div>
  </div>
</div>