<app-header></app-header>

<div class="pt-5 bolt-container pb-10">

    <div class="bolt-row">
        <div class="bolt-col">
            <span class="font-size-xl"><strong>Order confirmed!</strong></span>
        </div>
    </div>

    <div *ngFor="let selectedDevice of selectedDevices">
        <!-- UNCOMMENT THIS WHEN THINGS ARE NOT SHAKY -- depassa1-->
        <!-- <div class="bolt-row">
            <div class="pt-3 bolt-col">
                <span class="font-color-darker-crop-blue font-size-normal"><strong>{{
                        selectedDevice.vendorName }}</strong></span>
            </div>
        </div> -->
        <div class="pt-3 bolt-row">
            <div class="bolt-col font-size-normal">
                <span>{{getTotalDeviceCost(selectedDevice) | currency}}</span>
                &nbsp;&nbsp;
                <!-- <span><s class="opacity-zero-six">{{selectedDevice.cost | currency}}</s></span>
                &nbsp;&nbsp; -->
                <span>Save {{selectedDevice.discount}}%</span>
            </div>
        </div>
    </div>

    <div class="pt-4 pb-4 bolt-row">
        <div class="bolt-col">
            <span class="font-size-normal"><strong>What's next?</strong></span>
        </div>
    </div>

    <div class="whats-next-styles">
        <div class="bolt-container">
            <div class="bolt-row pt-5 pb-5">
                <div class="bolt-col">
                    <div class="bolt-row">
                        <div class="bolt-col-2 pt-1">
                            <img src="../../../assets/images/icon-mail.svg" alt="Mail Icon" class="centered">
                            <img src="../../../assets/images/icon-vertical-line.svg" alt="Vertical Line"
                                class="centered pt-1">
                        </div>
                        <div class="bolt-col pl-0">
                            <span class="order-conf-font-size">You will get an email confirmation and updates from
                                Nationwide's partner.</span>
                        </div>
                        <!-- UNCOMMENT THIS WHEN THINGS ARE NOT SHAKY -- depassa1-->
                        <!-- <div class="bolt-col pl-0">
                            <span class="order-conf-font-size">You will get an email confirmation and updates from
                                Nationwide's partner, {{ selectedDevices[0] ? selectedDevices[0].vendorName : ''
                                }}.</span>
                        </div> -->
                    </div>
                    <div class="bolt-row">
                        <div class="bolt-col-2 pt-1">
                            <img src="../../../assets/images/icon-house.svg" alt="Home Icon" class="centered">
                            <img src="../../../assets/images/icon-vertical-line.svg" alt="Vertical Line"
                                class="centered pt-1">
                        </div>
                        <div class="bolt-col min-content pl-0">
                            <span class="order-conf-font-size">Your device will come with instructions for easy home
                                installation.</span>
                        </div>
                    </div>
                    <div class="bolt-row">
                        <div class="bolt-col-2 pt-1">
                            <img src="../../../assets/images/icon-wrench.svg" alt="Install Icon" class="centered">
                        </div>
                        <div class="bolt-col pl-0">
                            <span class="order-conf-font-size">Install the device(s) within 55 days from your order to
                                keep
                                your policy
                                discount.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-survey *ngIf="false"></app-survey>

    <app-mobile-updates *ngIf="false"></app-mobile-updates>
</div>

<app-footer></app-footer>