export type DeviceOrder = {
  customerDetails?: CustomerDetails
  deviceTypeId: string
  policyNumber: string
  dataCollectionId: string
  programConsentFlag: string
  programConsentDt: string
  checkOutType: CheckOutType
}

type CustomerDetails = {
  firstName: string
  lastName: string
  emailId: string
  phoneNumber: string
  shippingDetails: ShippingDetails
}

type ShippingDetails = {
  addressLine1: string
  addressLine2: string
  addressLine3: string
  city: string
  state: string
  zipCode: string
}

export enum CheckOutType {
  FREE = "FREE",
  PAID = 'PAID',
}
