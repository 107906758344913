import { Component } from '@angular/core';

@Component({
  selector: 'app-water-devices',
  templateUrl: './water-devices.component.html',
  styleUrls: ['./water-devices.component.less']
})
export class WaterDevicesComponent {

}
