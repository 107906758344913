import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdditionalOrderInfoComponent } from './additional-order-info.component';

const routes: Routes = [
  { path: '**', component: AdditionalOrderInfoComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdditionalOrderInfoRoutingModule { }
