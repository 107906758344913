<app-header></app-header>

<div class="bolt-container pb-10">
    <div>
        <h2 class="text-center">Frequently asked questions</h2>
    </div>
    <bolt-accordion-group class="pb-5" header="General" [showtoggle]="true">
        <bolt-accordion *ngFor="let faq of faqs" header="{{ faq.question }}">
            <p [innerHTML]="faq.answer"></p>
        </bolt-accordion>
    </bolt-accordion-group>

    <div class="bolt-row">
        <div class="bolt-col-9"></div>
        <div class="bolt-col text-right">
            <bolt-button (click)="back()" type="primary">Back</bolt-button>
        </div>
    </div>
</div>