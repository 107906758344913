import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpError } from '../../types/http/error';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) { }

  redirectError(error: HttpError) {
    if (this.isBackendErrorAndContainsMessage(error, "User has already ordered the device")) {
      this.router.navigateByUrl('/error', { state: { errorEvent: 'alreadyOrderedDelayed' } });
    } else {
      this.router.navigateByUrl('/error');
    }
  };

  isBackendErrorAndContainsMessage(error: HttpError, message: string): boolean {
    return !!(error?.error?.developerMessage && error.error.developerMessage.includes(message));
  }
}
