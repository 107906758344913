import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Configuration } from '../../types/configuration';

export class ConfigurationInitializationError extends Error { }

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {

  private _appConfig = new BehaviorSubject<any>({});
  public readonly appConfig$: Observable<any> = this._appConfig.asObservable();

  constructor(private http: HttpClient) { }

  loadAppConfig(): Observable<any | void> {
    const configPath = '/assets/appConfig.json';

    return this.http.get(configPath).pipe(
      tap((config) => {
        this._appConfig.next(config);
      })
    );
  }

  get app(): Configuration {
    if (!this._appConfig.getValue()) {
      throw new ConfigurationInitializationError();
    }
    return this._appConfig.getValue();
  }
}
