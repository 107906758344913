import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Device } from 'src/app/core/types/device';
import { DeviceHelpersService } from 'src/app/core/services/helpers/device-helpers.service';


@Component({
  selector: 'app-device-recommendation-overview',
  templateUrl: './device-recommendation-overview.component.html',
  styleUrls: ['./device-recommendation-overview.component.less']
})
export class DeviceRecommendationOverviewComponent {

  @Input() device: Device = Input();

  constructor(
    private router: Router,
    private deviceHelperService: DeviceHelpersService
  ) { }

  ngOnInit() { }

  getTotalDeviceCost(): number {
    return this.deviceHelperService.getTotalDeviceCost(this.device);
  }

  navigateToDeviceLandingPage(device: Device): void {
    this.router.navigateByUrl(`/${device.shortName}`)
  }
}
