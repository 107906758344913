<div class="survey-outline mt-5 mb-5">

    <div class="bolt-container">
        <div class="bolt-row">
            <div class="bolt-col survey-header text-center">
                <p class="header-text font-size-md">We'd love to hear from you</p>
            </div>
        </div>
    </div>

    <div *ngFor="let surveyQuestion of survey;">
        <div class="bolt-container mt-3">
            <div class="bolt-row">
                <div class="bolt-col">
                    <span>{{surveyQuestion.question}} <strong>Select one</strong></span>
                </div>
            </div>
        </div>

        <div class="bolt-container mb-5">
            <div class="bolt-row">
                <div class="bolt-col font-size-lg">
                    <bolt-radio-group name="cutomerSurvey">
                        <bolt-radio *ngFor="let surveyAnswer of surveyQuestion.answers; let i = index"
                            [value]="surveyQuestion.answers[i].selected">{{surveyAnswer.answer}}</bolt-radio>
                    </bolt-radio-group>
                </div>
            </div>
        </div>
    </div>

    <div class="bolt-container">
        <bolt-button size="lg" type="primary" width="full" class="submit-survey">Submit</bolt-button>
    </div>

</div>