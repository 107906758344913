<app-header></app-header>

<bolt-waiting-indicator *ngIf="isLoading" class="pt-10 pb-10">Loading...</bolt-waiting-indicator>

<div *ngIf="!isLoading" class="smooth-scroll">
  <div class="bolt-container">

    <div class="bolt-row">
      <div class="bolt-col side-margin">
        <div class="pt-5">
          <span class="headline-style">
            {{deviceInfo.deviceHeaderText}}
          </span>
        </div>
      </div>
    </div>

    <div class="bolt-row pt-4">
      <div class="bolt-col side-margin">
        <span class="font-size-normal sub-heading">
          Nationwide's smart home program not only <b>offers a 10%
            discount*</b> on your
          policy,
          but
          access to free or
          discounted devices that can help protect your home.
        </span>
      </div>
    </div>

    <app-device-offer [device]="device" [howItWorksElement]="howItWorksElement!"></app-device-offer>

  </div>

  <app-device-education [device]="device.shortName"></app-device-education>

  <div class="bolt-container pb-10">

    <div class="pt-10" #howItWorks id="howItWorks">
      <app-how-device-works *ngIf="deviceInfo.howDeviceWorksValues" [deviceInfo]="deviceInfo"
        [videoInfo]="deviceInfo.howDeviceWorksValues.howDeviceWorksVideo"
        [cardInfo]="deviceInfo.howDeviceWorksValues.cardInfo ? deviceInfo.howDeviceWorksValues.cardInfo : []"></app-how-device-works>
    </div>

    <div *ngIf="deviceInfo.deviceInstallationVideo" class="pt-10" #howItWorks id="howDeviceInstalled">
      <app-how-device-works [deviceInfo]="deviceInfo"
        [videoInfo]="deviceInfo.deviceInstallationVideo"></app-how-device-works>
    </div>

    <div class="pt-10 pb-10" *ngIf="deviceInfo.testimonials && deviceInfo.testimonials.length > 0">
      <app-carousel [data]="deviceInfo.testimonials" title="Customer Testimonials"></app-carousel>
    </div>

    <div *ngIf="deviceInfo.faqs.length > 0">
      <app-smart-home-faqs [device]="device.shortName"></app-smart-home-faqs>
    </div>

  </div>

  <div [ngClass]="orderButtonOpacity(selectedDevices)" class="sticky-container bolt-container order-button">
    <div tabindex="0" class="sticky-button-container button-text" disabled="true" type="primary"
      (click)="navigate(validNumberOfDevices)" (keydown)="enterKeyPressed($event, validNumberOfDevices)">{{
      deviceOptionButtonText }}</div>
  </div>

  <div class="bolt-container flex mt-10 mb-10">
    <div class="key px-2" *ngIf="this.device.shortName == 'ting'; else other">
      <p><sup>1</sup> Reference design:
        https://energytoday.biz/blog/4-warning-signs-your-home-is-in-danger-of-an-electrical-fire#:~:text=You'll%20know%20if%20your,or%20charred%20outlets%20and%20switches
      </p>
      <p><sup>2</sup> https://www.esfi.org/home-electrical-fires/</p>
      <p>{{deviceInfo.disclaimer}}</p>

    </div>

    <ng-template #other>
      <div class="key px-2">
        <p><sup>1</sup> Reference design: Source link: https://www.rubyhome.com/blog/water-damage-stats/
        </p>
        <p><sup>2</sup> https://www.iii.org/fact-statistic/facts-statistics-homeowners-and-renters-insurance</p>
        <p>{{deviceInfo.disclaimer}}</p>
      </div>
    </ng-template>
  </div>
</div>

<app-footer></app-footer>