export const shouldUpdateDate = (d1: string, d2: string): boolean => {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();

    if (!d1 || date1 < date2) {
        return true;
    }
    return false;
}

export const compareDates = (d1: string, d2: string): number => {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();

    let returnValue: number = 0;
    if (date1 < date2) {
        returnValue = -1;
    } else if (date1 > date2) {
        returnValue = 1;
    } else {
        returnValue = 0;
    }
    return returnValue;
}