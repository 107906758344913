import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeviceLandingComponent } from './device-landing.component';

const routes: Routes = [
  {
    path: 'shipping-confirmation',
    loadChildren: () => import('../shipping-confirmation/shipping-confirmation.module').then((m) => m.ShippingConfirmationModule),
    data: { breadcrumb: 'shipping-confirmation' },
  },
  {
    path: 'additional-order-info',
    loadChildren: () => import('../additional-order-info/additional-order-info.module').then((m) => m.AdditionalOrderInfoModule),
    data: { breadcrumb: 'additional-order-info' },
  },
  {
    path: 'order-confirmation',
    loadChildren: () => import('../order-confirmation/order-confirmation.module').then((m) => m.OrderConfirmationModule),
    data: { breadcrumb: 'order-confirmation' },
  },
  {
    path: 'device-faqs/:device',
    loadChildren: () => import('../device-faqs/device-faqs.module').then((m) => m.DeviceFaqsModule),
    data: { breadcrumb: 'device-faqs' },
  },
  { path: '**', component: DeviceLandingComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeviceLandingRoutingModule { }