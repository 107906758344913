import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from 'src/app/core/services/profile.service';

@Component({
  selector: 'error',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.less']
})
export class ErrorComponent {

  errorMessaging: any = {
    generic: {
      title: "Oops!",
      description: "Looks like something went wrong."
    },
    alreadyOrdered: {
      title: "Looks like you already ordered a device",
      description: "You’re a step closer to preventing damage to your home!"
    },
    alreadyOrderedDelayed: {
      title: "Looks like you already ordered a device",
      description: "You’re a step closer to preventing damage to your home!"
    },
    authenticationFailure: {
      title: 'Failed to authenticate!',
      description: 'Something went wrong with the authentication process.  Please try again.'
    },
    pageDoesNotExist: {
      title: 'Oops!',
      description: 'Looks like this page does not exist.'
    },

    technicalDifficulties: {
      title: "We know you're excited about protecting your home from water leaks - we are too!",
      description: "Unfortunately we are experiencing technical issues. Keep your eye out for a reminder email communication from Nationwide to order your device."
    }
  }

  messaging: any = this.errorMessaging.generic;
  routeData: any = {};
  state: any = {};
  dataCollectionId: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService,
  ) {
    this.state = this.router.getCurrentNavigation()?.extras?.state;
    this.route.data.subscribe((value) => {
      this.routeData = value;
    });
  }

  ngOnInit() {
    if (this.routeData['pageExists'] === false) {
      this.messaging = this.errorMessaging.pageDoesNotExist;
    } else if (!!this.state && (['alreadyOrdered'].includes(this.state['errorEvent'])
      || ['technicalDifficulties'].includes(this.state['errorEvent'])
      || ['alreadyOrderedDelayed'].includes(this.state['errorEvent']))) {
      this.messaging = this.errorMessaging[this.state['errorEvent']];
    }

    this.profileService.profile$.subscribe((profile) => {
      this.dataCollectionId = profile.dataCollectionId;
    })
  }
}
