import { Component } from '@angular/core';

import { Device } from 'src/app/core/types/device';
import { DeviceHelpersService } from 'src/app/core/services/helpers/device-helpers.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { UserProfile } from 'src/app/core/types/userProfile';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.less']
})
export class OrderConfirmationComponent {

  profile: UserProfile | undefined = {} as UserProfile;
  selectedDevices: Device[] = [];

  constructor(
    private deviceHelperService: DeviceHelpersService,
    private profileService: ProfileService,
  ) {
    this.profileService.profile$.subscribe((profile) => {
      this.profile = profile;
    })
  }

  ngOnInit() {
    this.selectedDevices = this.getSelectedDevices(this.profile);
  }

  getSelectedDevices(profile: UserProfile | undefined): Device[] {
    if (profile) {
      return this.deviceHelperService.getSelectedDevices(profile.devices);
    }
    return [];
  }

  getTotalDeviceCost(device: Device): number {
    return this.deviceHelperService.getTotalDeviceCost(device);
  }
}
