import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceOfferModule } from 'src/app/core/components/device-offer/device-offer.module';
import { FooterModule } from 'src/app/core/components/footer/footer.module';
import { HeaderModule } from 'src/app/core/components/header/header.module';
import { HowDeviceInstalledModule } from 'src/app/core/components/how-device-installed/how-device-installed.module';
import { HowDeviceWorksModule } from 'src/app/core/components/how-device-works/how-device-works.module';
import { DeviceLandingComponent } from './device-landing.component';
import { SmartHomeFaqsModule } from 'src/app/core/components/smart-home-faqs/smart-home-faqs.module';
import { CarouselModule } from 'src/app/core/components/carousel/carousel.module';
import { DeviceLandingRoutingModule } from './device-landing-routing.module';
import { ShippingConfirmationModule } from '../shipping-confirmation/shipping-confirmation.module';
import { OrderConfirmationModule } from '../order-confirmation/order-confirmation.module';
import { DeviceEducationModule } from 'src/app/core/components/device-education/device-education.module';
import { AdditionalOrderInfoModule } from '../additional-order-info/additional-order-info.module';
import { DeviceFaqsModule } from '../device-faqs/device-faqs.module';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    DeviceLandingComponent,
  ],
  imports: [
    CommonModule,
    DeviceOfferModule,
    FooterModule,
    HeaderModule,
    HowDeviceInstalledModule,
    HowDeviceWorksModule,
    SmartHomeFaqsModule,
    CarouselModule,
    DeviceLandingRoutingModule,
    ShippingConfirmationModule,
    OrderConfirmationModule,
    DeviceEducationModule,
    AdditionalOrderInfoModule,
    DeviceFaqsModule,
  ],
})
export class DeviceLandingModule { }
