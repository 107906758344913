import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderModule } from 'src/app/core/components/header/header.module';
import { RecommendationsRoutingModule } from './recommendations-routing.module';
import { RecommendationsLandingModule } from './recommendations-landing/recommendations-landing.module';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [

  ],
  imports: [
    CommonModule,
    HeaderModule,
    RecommendationsRoutingModule,
    RecommendationsLandingModule
  ]
})
export class RecommendationsModule { }
