import { NgModule } from '@angular/core';
import { AuthModule, StsConfigLoader } from 'angular-auth-oidc-client';
import { AuthorizeAdminService } from '../core/services/authorize-admin/authorize-admin.service';


@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: (authService: AuthorizeAdminService) =>
          authService.createConfigLoader(),
        deps: [AuthorizeAdminService],
      },
    }),
  ],
  exports: [AuthModule],
  providers: [AuthorizeAdminService],
})
export class AuthConfigModule { }
