import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuickAuthenticationRoutingModule } from './quick-authentication-routing.module';
import { QuickAuthenticationComponent } from './quick-authentication/quick-authentication.component';
import { HeaderModule } from 'src/app/core/components/header/header.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularIFrameModule } from '@nationwide/angular-iframe-component';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    QuickAuthenticationComponent
  ],
  imports: [
    AngularIFrameModule,
    CommonModule,
    HeaderModule,
    HttpClientModule,
    QuickAuthenticationRoutingModule,
    ReactiveFormsModule,
  ]
})
export class QuickAuthenticationModule { }
