import { Component } from '@angular/core';

@Component({
  selector: 'app-recommendations-landing',
  templateUrl: './recommendations-landing.component.html',
  styleUrls: ['./recommendations-landing.component.less']
})
export class RecommendationsLandingComponent {

}
