<div>
    HELP ME PLEASE

    <table>
        <tr>
            <bolt-button (click)="navToDeviceLandingPage()">Nav to device landing</bolt-button>
        </tr>
        <tr>
            <bolt-button type="primary" (click)="updateCurrentDevice()">Update current device</bolt-button>
        </tr>
        <tr>
            {{ profile | json }}
        </tr>
    </table>
</div>