import { inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, UrlTree } from '@angular/router';
import { UserPrivilege } from '../../constants';
import { ProfileService } from '../profile.service';
import { AuthorizeAdminService } from '../authorize-admin/authorize-admin.service';
import { EventTypes, OidcSecurityService, PublicEventsService, UserDataResult } from 'angular-auth-oidc-client';
import { filter, first, map, Observable, of } from 'rxjs';
import { AuthenticatedUser } from '../../types/authenticated-user';
import { UserProfile } from '../../types/userProfile';
import { PermissionGroup } from '../../types/configuration';

@Injectable({
  providedIn: 'root'
})
class AuthGuardService {

  private profile: UserProfile = new UserProfile();

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private authAdminService: AuthorizeAdminService,
    private oidcSecurityService: OidcSecurityService,
    private oidcEventService: PublicEventsService
  ) {
    this.profileService.profile$.subscribe((profile: UserProfile) => {
      this.profile = profile;
    });

    this.oidcEventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
      .subscribe((eventResult) => {
        this.oidcSecurityService.getAuthenticationResult().subscribe((result) => {
          if (eventResult.value?.isAuthenticated) {
            this.setToken(true);
            console.log("--- Refresh Successful ---");
          }
        });
      });
  }

  setToken(overrideToken: boolean = false): void {
    this.oidcSecurityService.getAccessToken().subscribe((token) => {
      if (token && overrideToken || (!overrideToken && !sessionStorage.getItem('access_token'))) {
        if (!!token) sessionStorage.setItem('access_token', token);
      }
    });
  }

  isUserAuthenticated(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    this.setToken(false);
    let isAuthenticated: boolean = false;
    if ((!!this.profile.privilegeLevel && this.profile.privilegeLevel >= UserPrivilege.User) || !!sessionStorage.getItem('access_token')) {
      isAuthenticated = true;
    }
    return isAuthenticated ? true : this.router.createUrlTree(['quick-auth'], { queryParams: next.queryParams });
  }

  isAdminAuthorized(next: ActivatedRouteSnapshot, state: RouterStateSnapshot, groupsToCheck: PermissionGroup): Observable<boolean | UrlTree> {
    this.setToken(true);
    if (this.profile.privilegeLevel && this.profile.privilegeLevel >= UserPrivilege.Admin) {
      return of(true);
    }
    return this.oidcSecurityService.userData$.pipe(
      filter(
        (userDataResult: UserDataResult): boolean => {
          return !!userDataResult.userData
        }
      ),
      map(
        (userDataResult: UserDataResult): AuthenticatedUser => {
          return userDataResult.userData;
        }
      ),
      map((user: AuthenticatedUser): boolean => {
        let hasPermittedGroup: boolean = this.authAdminService.hasPermittedGroup(user.groups, groupsToCheck);
        if (this.profile && groupsToCheck === PermissionGroup.permittedAdminGroups && hasPermittedGroup) {
          this.profile.privilegeLevel = UserPrivilege.Admin;
        }
        return hasPermittedGroup;
      }
      ),
      map((isAuthorized: boolean): boolean | UrlTree => {
        if (!isAuthorized) {
          return this.router.parseUrl('/error');
        } else {
          return isAuthorized;
        }
      }),
      first());
  }
}

export const IsAuthenticated: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree => {
  return inject(AuthGuardService).isUserAuthenticated(next, state);
}

export const IsAdmin: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
  return inject(AuthGuardService).isAdminAuthorized(next, state, PermissionGroup.permittedAdminGroups);
}

export const IsProviderSearchPermitted: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
  return inject(AuthGuardService).isAdminAuthorized(next, state, PermissionGroup.permittedProviderSearchGroups);
}

export default AuthGuardService;
