import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

import { lastValueFrom } from 'rxjs'
import { DeviceResponse } from '../../types/device';
import { SortParameters } from '../../types/http/sortParameters';
import { InventoryAvailabilityList } from '../../types/http/inventoryAvailabilityList';
import { NewInventoryDeviceType, UpdatedInventoryDeviceType } from '../../types/http/newInventoryDeviceType';
import { UUID } from '../../types/uuid';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private api: ApiService) { }

  async getInventoryDetails(sortParams?: SortParameters): Promise<InventoryAvailabilityList> {
    return await lastValueFrom(this.api.apigeeGet('inventory', sortParams)).catch((err) => { throw err });
  }

  async createInventoryManagement(newInventoryDeviceType: NewInventoryDeviceType): Promise<{ 'availabilityCount': number }> {
    return await lastValueFrom(this.api.apigeePost('inventory', newInventoryDeviceType)).catch((err) => { throw err });
  }

  async updateDeviceAvailability(inventoryId: string, updateDetails: UpdatedInventoryDeviceType): Promise<{ 'availabilityCount': number }> {
    return await lastValueFrom(this.api.apigeePatch(`inventory/${inventoryId}`, updateDetails)).catch((err) => { throw err });
  }

  async getDeviceTypes(inventoryId: UUID): Promise<DeviceResponse[]> {
    return await lastValueFrom(this.api.apigeeGet(`inventory/${inventoryId}/deviceType`)).catch((err) => { throw err });
  }
}
