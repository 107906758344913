import { Component, Input } from '@angular/core';
import { DeviceInfo, DeviceStaticInfo, QA } from '../../types/deviceInfo';
import { SmartHomeStaticInfo } from '../../types/smartHomeInfo';
import { Router } from '@angular/router';
import { VendorName } from '../../types/device';

@Component({
  selector: 'app-smart-home-faqs',
  templateUrl: './smart-home-faqs.component.html',
  styleUrls: ['./smart-home-faqs.component.less']
})
export class SmartHomeFaqsComponent {

  // Inputs/Outputs
  @Input() device: VendorName | 'smartHome' = VendorName.LEAKBOT;

  // Component-level variables
  faqs: QA[] = [];
  headerString: string = '';

  constructor(private router: Router) { }

  ngOnInit() {
    if (this.device) {
      this.faqs = this.device === 'smartHome' ? SmartHomeStaticInfo.faqs : DeviceStaticInfo[this.device].faqs;
      this.headerString = this.faqs.length > 1 ? 'FAQs' : 'FAQ';
    }
  }

  navToDeviceFAQs(device: string): void {
    this.router.navigate(["landing/device-faqs/" + device]);
  }
}
