import { Injectable } from '@angular/core';
import { LDClient, LDFlagSet, LDOptions, LDUser, initialize } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, config, distinctUntilChanged, Observable } from 'rxjs';
import * as ld from 'launchdarkly-js-client-sdk';
import { filterNullish } from '../helpers';

export type FeatureFlags = {
    [key: string]: FeatureFlag;
};
export type FeatureFlag = number | string | boolean | object;

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService {

    clientSideID = "6436e3d51af13e137918b13f"; //todo: pull into config file?

    context: ld.LDContext = { //todo: pull into config file?
        kind: 'user',
        key: 'thomc43',
    }

    OPTIONS: LDOptions = {
        bootstrap: 'localStorage',
    };

    private launchDarkly!: LDClient;
    private launchDarklyUser = '';

    private featureFlagsSubject$: BehaviorSubject<FeatureFlags | null> = new BehaviorSubject<FeatureFlags | null>(null);
    public readonly featureFlags$: Observable<FeatureFlags> = this.featureFlagsSubject$.asObservable().pipe(filterNullish());

    constructor() {
        initialize(this.clientSideID, this.context, this.OPTIONS);
    }
}
