<div *ngIf="videoInfo" class="bolt-container">
    <div class="font-size-lg text-center pb-2 heading-weight">
        {{ videoInfo.title }}
    </div>
    <div *ngIf="videoInfo?.subtitle != ''" class="font-size-xs">
        {{videoInfo.subtitle}}
    </div>
    <div class="text-center pt-2">
        <iframe class="video" class="video" [src]="videoInfo.videoPath | urlSanitize" title="YouTube video player"
            frameborder="0" width="640" height="360"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
    </div>
</div>

<app-carousel *ngIf="cardInfo.length > 0" [device]="device" [data]="cardInfo" title="How it works"></app-carousel>