import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthCallbackComponent } from './auth-callback.component';



@NgModule({
  declarations: [
    AuthCallbackComponent
  ],
  imports: [
    CommonModule
  ]
})
export class AuthCallbackModule { }
