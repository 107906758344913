<app-header></app-header>
<!-- SHIPPING CONFIRMATION [FREE] -->
<div *ngIf="!isLoading" class="pb-10">
  <div class="bolt-container">
    <div class="pt-5 bolt-row">
      <div class="bolt-col">
        <span class="font-size-xl"><strong>Shipping information</strong></span>
      </div>
    </div>
    <div class="bolt-row pt-4">
      <div class="bolt-col">
        <p class="font-size-normal no-padding">Review the information below and agree to the terms and conditions
          before
          ordering.
        </p>
      </div>
    </div>
    <div *ngFor="let selectedDevice of getSelectedDevices(profile)">
      <!-- UNCOMMENT WHEN THINGS SETTLE DOWN --depassa1 -->
      <!-- <div class="bolt-row pt-4">
        <div class="bolt-col">
          <span
            class="font-color-darker-crop-blue font-size-normal"><strong>{{selectedDevice.vendorName}}</strong></span>
        </div>
      </div> -->
      <div class="bolt-row pt-3 device-info-space">
        <div class="bolt-col font-size-normal">
          <span>{{getTotalDeviceCost(selectedDevice) | currency}}</span>
          &nbsp;&nbsp;
          <!-- <span><s class="opacity-zero-six">{{selectedDevice.cost | currency}}</s></span>
                  &nbsp;&nbsp; -->
          <span>Save {{selectedDevice.discount}}%</span>
        </div>
      </div>
    </div>

    <div class="bolt-container customer-info-styles">
      <div *ngIf="!isLoadingCustomer">
        <div class="customer-section-top-padding bolt-row">
          <div class="bolt-col font-size-md">
            <span><strong>Name</strong></span>
          </div>
        </div>
        <div class="bolt-row">
          <div class="bolt-col font-size-md">
            <span>{{profile?.customer?.firstName | titlecase}} {{profile?.customer?.lastName| titlecase}}</span>
          </div>
        </div>

        <div class="bolt-row pad-top-18">
          <div class="bolt-col font-size-md">
            <span><strong>Shipping Address</strong></span>
          </div>
        </div>
        <div class="bolt-row">
          <div class="bolt-col font-size-md">
            <span>The device will be shipped to the insured address on your home policy. To
              validate your address you
              can
              <a class="inline-display" href="https://www.nationwide.com/account-login" target="_blank"
                rel="noopener noreferrer">log into your Nationwide
                account.</a></span>
          </div>
        </div>
        <div class="bolt-row">
          <div class="bolt-col">
            <p class="font-size-normal"><i><b>Devices must be installed at the property associated with your
                  policy.</b> Please <a href="https://www.nationwide.com/personal/contact/insurance/" target="_blank"
                  rel="noopener noreferrer" class="text-black-important">contact us</a> if the address is incorrect.</i>
            </p>
          </div>
        </div>
      </div>

      <bolt-waiting-indicator *ngIf="isLoadingCustomer" class="pt-10 pb-10">Loading Your Information...
      </bolt-waiting-indicator>
    </div>
    <div class="pt-3 bolt-row mb-3">
      <div class="bolt-col">
        <div *ngIf="termsError" class="bolt-row checkbox-error-margin pt-5 pb-5">
          <div class="icon-spacing">
            <bolt-icon name="exclamation-circle-filled" color="cherry-red"></bolt-icon>
          </div>
          <div class="bolt-col">
            <i>{{ AGREE_TO_TERMS_ERROR }}</i>
          </div>
        </div>

        <!-- <bolt-checkbox [value]="agreedToTerms" required> -->
        <bolt-checkbox value="agree" (change)="agreedToTerms = !agreedToTerms;checkAgreedToTerms(agreedToTerms)"
          required class="pt-5 pb-5">
          <span class="font-size-normal">I agree to Nationwide and their corresponding partners <a
              class="text-black-important inline-display"
              href="https://www.nationwide.com/personal/insurance/homeowners/smart-home/terms-2" target="_blank">Terms &
              Conditions</a></span>
        </bolt-checkbox>
      </div>
    </div>
  </div>

  <div class="sticky-container bolt-container order-button">
    <div class="sticky-button-container button-text" disabled="true" type="primary" (click)="sendDeviceOrder()">
      Place order
    </div>
  </div>
</div>

<bolt-waiting-indicator *ngIf="isLoading" class="mt-10 pt-10">Submitting your order...</bolt-waiting-indicator>

<app-footer></app-footer>