<div class="bolt-footer">
  <div class="bolt-container-fluid">
    <div class="bolt-footer__head">
      <div class="bolt-footer__intro">
        <div class="bolt-footer__logo">
          <bolt-logo color="white" href="/landing?dataCollectionId={{dataCollectionId}}" type="nw-vertical"></bolt-logo>
        </div>
        <div class="bolt-footer__phone">
          <span class="bolt-footer__phone__alpha">
            1-877 On Your Side
          </span>
          <span>(1-877-669-6877)</span>
        </div>
        <nav class="bolt-footer__social">
          <ul>
            <li>
              <a id="facebook" aria-label="Nationwide on Facebook" class="social-sprite social-link--facebook "
                href="https://www.facebook.com/nationwide" target="_blank">
                <bolt-icon name="facebook"></bolt-icon>
              </a>
            </li>
            <li>
              <a id="twitter" aria-label="Nationwide on Twitter" class="social-sprite social-link--twitter"
                href="https://twitter.com/nationwide" target="_blank">
                <bolt-icon name="twitter"></bolt-icon>
              </a>
            </li>
            <li><a id="instagram" aria-label="Nationwide on Instagram" class="social-sprite social-link--instagram"
                href="https://www.instagram.com/nationwide/" target="_blank">
                <bolt-icon name="instagram"></bolt-icon>
              </a>
            </li>
            <li><a id="linkedIn" aria-label="Nationwide on LinkedIn" class="social-sprite social-link--linkedIn"
                href="https://www.linkedin.com/company/2340/" target="_blank">
                <bolt-icon name="linkedin"></bolt-icon>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <nav>
        <ul>
          <li><a href="https://www.nationwide.com/personal/about-us/" target="_blank" rel="noopener noreferrer">About
              Us</a></li>
          <li><a href="https://www.nationwide.com/personal/contact/insurance/" target="_blank"
              rel="noopener noreferrer">Contact Us</a></li>
          <li><a href="https://blog.nationwide.com/" target="_blank" rel="noopener noreferrer">Now From Nationwide®
              Blog</a></li>
        </ul>
      </nav>
    </div>
    <div class="bolt-footer__body">
      <div class="bolt-footer__content">
        <p>
          Products underwritten by Nationwide Mutual Insurance Company and Affiliated Companies. Not all
          Nationwide
          affiliated
          companies are mutual companies, and not all Nationwide members are insured by a mutual company.
          Subject to
          underwriting guidelines, review and approval. Products and discounts not available to all persons in
          all states.
          Nationwide Investment Services Corporation,
          member <a href="https://www.finra.org/#/" target="_blank">FINRA</a>. Home Office: One Nationwide
          Plaza,
          Columbus,
          OH. Nationwide, the Nationwide N and Eagle and other marks displayed on this page are service marks
          of
          Nationwide
          Mutual Insurance Company, unless otherwise disclosed. ©2023. Nationwide Mutual Insurance Company.
        </p>
        <p>
          &copy;2023 Nationwide Mutual Insurance Company and affiliated companies
        </p>
      </div>
    </div>
    <div class="bolt-footer__tail">
      <div class="bolt-footer__tagline">
        Nationwide is on your side<sup>&reg;</sup>
      </div>
    </div>
  </div>
</div>