import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceRecommendationsComponent } from './device-recommendations.component';
import { DeviceRecommendationOverviewModule } from './device-recommendation-overview/device-recommendation-overview.module';



@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  declarations: [
    DeviceRecommendationsComponent
  ],
  imports: [
    CommonModule,
    DeviceRecommendationOverviewModule
  ],
  exports: [
    DeviceRecommendationsComponent,
  ]
})
export class DeviceRecommendationsModule { }
