import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Address, UnidentifiedUser, UserProfile } from 'src/app/core/types/userProfile';
import { Device, DeviceOfferType } from 'src/app/core/types/device';
import { DeviceHelpersService } from 'src/app/core/services/helpers/device-helpers.service';
import { CheckOutType, DeviceOrder } from 'src/app/core/types/http/deviceOrder';
import { ProfileHelpersService } from 'src/app/core/services/helpers/profile-helpers.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { PolicyDetailsService } from 'src/app/core/services/policy-details/policy-details.service';
import { DeviceService } from 'src/app/core/services/device/device.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { PolicyType } from 'src/app/core/types/policy-info';

@Component({
  selector: 'app-shipping-confirmation',
  templateUrl: './shipping-confirmation.component.html',
  styleUrls: ['./shipping-confirmation.component.less']
})
export class ShippingConfirmationComponent {

  profile: UserProfile | undefined = {} as UserProfile;
  selectedAddress: Address | undefined;
  policyAddresses: Address[] = [];
  alternateAddresses: Address[] = [];
  agreedToTerms: boolean = false;
  termsError: boolean = false;
  deviceData: Device[] = [];
  programConsentFlag: string = "N";
  dataCollectionId: string = '';
  isLoading: boolean = false;
  isLoadingCustomer: boolean = false;
  checkOutType: CheckOutType = CheckOutType.FREE;

  AGREE_TO_TERMS_ERROR: string = 'Make sure you have read and agree to the terms and conditions.';

  constructor(
    private router: Router,
    private deviceService: DeviceService,
    private profileService: ProfileService,
    private profileHelpersService: ProfileHelpersService,
    private deviceHelperService: DeviceHelpersService,
    private policyDetailsService: PolicyDetailsService,
    private navigationService: NavigationService
  ) {
    this.profileService.profile$.subscribe((profile) => {
      this.profile = profile;
      this.selectedAddress = this.profileHelpersService.getSelectedAddress(this.profile);
      this.policyAddresses = this.getAddressData(profile, true);
      this.alternateAddresses = this.getAddressData(profile, false);
      this.dataCollectionId = this.profile?.dataCollectionId ? this.profile.dataCollectionId : '';
    })
  }

  ngOnInit(): void {
    this.isLoadingCustomer = true;
    if (this.profile?.policyNumber) {
      this.policyDetailsService.getPolicyOwnerName(this.profile?.policyNumber, this.profile?.policyType?.toLowerCase() as PolicyType).then((response: UnidentifiedUser[]) => {
        if (this.profile) {
          let name = response.at(0);
          this.profile.customer.firstName = name?.person.firstName
        }
      }).finally(() => {
        this.isLoadingCustomer = false;
      });
    }
    this.deviceData = this.getSelectedDevices(this.profile);
  }

  navToConfirmation(): void {
    this.router.navigateByUrl(`landing/order-confirmation`);
  }

  getAddressData(profile: UserProfile | undefined, isPolicyAddress: boolean): Address[] {
    if (profile?.customer.addresses) {
      const foundAddresses = this.profileHelpersService.findPolicyOrAlternateAddresses(profile.customer.addresses, isPolicyAddress);
      return foundAddresses;
    }
    return [];
  }

  getSelectedDevices(profile: UserProfile | undefined): Device[] {
    if (profile) {
      return this.deviceHelperService.getSelectedDevices(profile.devices);
    }
    return [];
  }

  getTotalDeviceCost(device: Device): number {
    return this.deviceHelperService.getTotalDeviceCost(device);
  }

  async updateSelectedAddress(profile: UserProfile | undefined, selectedAddress: Address | undefined): Promise<void> {
    await this.profileHelpersService.updateSelectedAddress(profile, selectedAddress);
  }

  async updateSelectedAddressAndNavToConfirmation(profile: UserProfile | undefined,
    selectedAddress: Address | undefined,
    agreedToTerms: boolean): Promise<void> {
    if (agreedToTerms) {
      await this.updateSelectedAddress(profile, selectedAddress);
      this.navToConfirmation();
    }
    else {
      this.termsError = true;
    }
  }

  checkAgreedToTerms(agreedToTerms: boolean): void {
    if (agreedToTerms) {
      this.termsError = false;
      this.programConsentFlag = "Y";
    } else {
      this.termsError = true;
    }
  }

  sendDeviceOrder() {
    if (this.agreedToTerms) {
      this.isLoading = true;

      const todaysDate: string = JSON.stringify(new Date());
      let order: DeviceOrder = {
        deviceTypeId: this.deviceData[0].deviceTypeId.toString(),
        policyNumber: this.profile!.policyNumber,
        dataCollectionId: this.dataCollectionId,
        programConsentFlag: this.programConsentFlag,
        programConsentDt: todaysDate.substring(1, todaysDate.length - 1),
        checkOutType: CheckOutType.FREE
      }

      this.deviceService.placeDeviceOrder(order).then((response: any) => {
        this.updateSelectedAddressAndNavToConfirmation(this.profile, this.selectedAddress, this.agreedToTerms);
      }).catch((err: HttpErrorResponse) => {
        this.navigationService.redirectError(err);
      }).finally(() => {
        this.isLoading = false;
      });
    } else {
      this.termsError = true;
    }
  }

}
