import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecommendationsLandingComponent } from './recommendations-landing/recommendations-landing.component';

const routes: Routes = [
  {
    path: 'shipping-confirmation',
    loadChildren: () => import('./../shipping-confirmation/shipping-confirmation.module').then((m) => m.ShippingConfirmationModule),
    data: { breadcrumb: 'shipping-confirmation' },
  },
  {
    path: 'order-confirmation',
    loadChildren: () => import('./../order-confirmation/order-confirmation.module').then((m) => m.OrderConfirmationModule),
    data: { breadcrumb: 'order-confirmation' },
  },
  { path: '**', component: RecommendationsLandingComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecommendationsRoutingModule { }
