import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigLoaderService } from '../config-loader/config-loader.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apigeeHostUrl = '';
  private readonly BASE_PATH = 'policy-management/smarthome-experience-enrollments/v1';
  private readonly BASE_POLICY_PATH = 'policy-management';

  constructor(private http: HttpClient, private config: ConfigLoaderService) {
    this.config.appConfig$.subscribe((config) => {
      this.apigeeHostUrl = config?.secrets?.smartHomeMarket ? config.secrets.smartHomeMarket.apigeeHostUrl : '';
    });
  }

  apigeeGet(endpointPath: string, options?: any): Observable<any> {
    return this.http.get(`${this.apigeeHostUrl}/${this.BASE_PATH}/${endpointPath}`, options);
  }

  apigeePost(endpointPath: string, body: any, options?: any): Observable<any> {
    return this.http.post(`${this.apigeeHostUrl}/${this.BASE_PATH}/${endpointPath}`, body, options);
  }

  apigeePatch(endpointPath: string, body: any, options?: any): Observable<any> {
    return this.http.patch(`${this.apigeeHostUrl}/${this.BASE_PATH}/${endpointPath}`, body, options);
  }

  apigeePolicyCenterGet(endpointPath: string, options?: any): Observable<any> {
    return this.http.get(`${this.apigeeHostUrl}/${this.BASE_POLICY_PATH}/${endpointPath}`, options);
  }
}
