import { Injectable } from '@angular/core';

import { Address, UserProfile } from '../../types/userProfile';

@Injectable({
  providedIn: 'root'
})
export class ProfileHelpersService {

  constructor() { }

  findPolicyOrAlternateAddresses(addresses: Address[], isPolicyAddress: boolean): Address[] {
    return addresses.filter((address: Address) => {
      return isPolicyAddress ? address.isPolicyAddress : !address.isPolicyAddress;
    });
  }

  updateSelectedAddress(profile: UserProfile | undefined, selectedAddress: Address | undefined): void {
    if (profile?.customer.addresses) {
      profile.customer.addresses.forEach((address: Address) => {
        address.selected = address === selectedAddress;
      });
    }
  }

  getSelectedAddress(profile: UserProfile | undefined): Address | undefined {
    let policyAddress: Address | undefined;
    if (profile?.customer.addresses && profile.customer.addresses.length > 0) {
      policyAddress = profile.customer.addresses.find((address: Address) => {
        return address.isPolicyAddress;
      });
      if (!policyAddress) policyAddress = profile.customer.addresses[0];
    }
    return policyAddress;
  }

}
