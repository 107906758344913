export type InventoryResponse = {
    _embedded: {
        inventory: [
            {
                availabilityCount: number,
                _links: {
                    self: {
                        href: string
                    },
                    inventoryManagement: {
                        href: string,
                        templated: boolean
                    },
                    deviceType: {
                        href: string
                    }
                }
            }
        ]
    },
    _links: {
        self: {
            href: string
        },
        profile: {
            href: string

        },
        search: {
            href: string
        }
    },
    page: {
        size: number,
        totalElements: number,
        totalPages: number,
        number: number
    },
    vendorInventoryInfo: VendorInventoryResponse,
    deviceInventoryInfo: DeviceInventoryResponse,
}

export type DeviceInventoryResponse = {
    deviceName: string,
    deviceAddedDt: Date,
    cost: number,
    discountAmount: number,
    installationType: string,
    _links: {
        self: {
            href: string
        },
        deviceType: {
            href: string
        },
        vendor: {
            href: string
        }
    }
}

export type VendorInventoryResponse = {
    recordAddTs: Date,
    recordAddUserId: string,
    processNm: string,
    vendorName: string,
    _links: {
        self: {
            href: string
        },
        vendor: {
            href: string
        }
    }
}
export class InventoryInfo {
    availabilityCount: any = {
        originalValue: 0,
        newValue: 0
    };
    cost: any = {
        originalValue: 0,
        newValue: 0
    };
    deviceType: string = "";
    inventoryId: string = "";
    deviceId: string = "";
    discountAmount: number = 0;
    vendor: string = "";
    deviceTypeURL: string = "";
    recordUpdateTs: string = "";

    constructor(obj: any);

    constructor(obj?: any) {
        if (obj) {
            this.availabilityCount = obj.availabilityCount;
            this.cost = obj.cost;
            this.deviceType = obj.deviceType;
            this.inventoryId = obj.inventoryId;
            this.deviceId = obj.deviceId;
            this.discountAmount = obj.discountAmount;
            this.vendor = obj.vendor;
            this.deviceTypeURL = obj.deviceTypeURL;
            this.recordUpdateTs = obj.recordUpdateTs;
        }
    }
}