import { Component, Input, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { SmartHomeProduct } from '../../types/product';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  @Input() product: SmartHomeProduct = SmartHomeProduct.GENERAL;
  @Input() imageSrc: string | undefined;
  @Input() showMenu: boolean = true;
  @Input() imageLinkable: boolean = true;

  dataCollectionId: string = "";
  redirectURL: string = "";

  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
    this.profileService.profile$.subscribe((profile) => {
      this.dataCollectionId = profile.dataCollectionId;
      this.imageLinkable ? this.redirectURL = `/landing?dataCollectionId=${this.dataCollectionId}` : '';
    })
  }

}