import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecommendationsLandingComponent } from './recommendations-landing.component';
import { DeviceRecommendationsModule } from 'src/app/core/components/device-recommendations/device-recommendations.module';
import { SmartHomeFaqsModule } from 'src/app/core/components/smart-home-faqs/smart-home-faqs.module';



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    RecommendationsLandingComponent
  ],
  imports: [
    CommonModule,
    DeviceRecommendationsModule,
    SmartHomeFaqsModule
  ]
})
export class RecommendationsLandingModule { }
