export interface FAQs {
  question: string;
  answer: string;
}

export const LeakBotFAQs: FAQs[] = [
  {
    question: "What is the LeakBot service offered by Nationwide?",
    answer: "Nationwide wants to help keep families and homes safe. As part of that mission, Nationwide has partnered with LeakBot, to provide access to a simple, secure, smart water leak detector, and plumbing repair support at no additional charge. With the LeakBot device and app, you'll get access to and notifications about water usage insights, daily plumbing checks and leak alerts. LeakBot is proven to prevent small leaks from becoming a major water issue."
  },
  {
    question: "Where do I install LeakBot?",
    answer: "LeakBot needs to be installed between 30-50cm (1-2 ft) along the internal incoming mains water supply pipe inside your home from the point that it enters your property and before the pipe branches off around your home. If there is any pipe insulation fitted below / before the LeakBot install position it will need to be removed. For more details, please check our <a href=\"https://leakbot.io/wp-content/uploads/2022/09/LeakBot_Device_Install_Guide.pdf\" target=\"_blank\"  rel=\"noopener noreferrer\">installation guide</a>."
  },
  {
    question: "How does LeakBot detect leaks?",
    answer: "LeakBot's patented Thermi-Q technology accurately measures both the air and pipe temperatures in your home. If you have a leak, it will continually draw colder water from outside into your home, creating a consistent drop in temperature. LeakBot can sense the prolonged and consistent drop in temperature and alerts you to the problem."
  },
  {
    question: "What happens when I book a LeakBot repair visit?",
    answer: "LeakBot engineers will check the entire mains water plumbing system inside your home and will fix all leaks found in line with our repair terms and conditions. They will also use our patented leak detection equipment to confirm your property is leak free before they leave."
  },
  {
    question: "Can LeakBot be installed onto different size water pipes?",
    answer: "LeakBot is designed to be installed on pipework with a diameter of 3/4 inch and 1 inch."
  },
  {
    question: "Can I take LeakBot with me if I move homes?",
    answer: "Yes. If you move homes, you can take LeakBot with you. You will need to connect your LeakBot to the Wi-Fi in your new home by following the reconnection instructions in the LeakBot app. Please remember to contact our LeakBot support team with your new address details so we can update your account."
  },
  {
    question: "How does LeakBot alert me of a leak?",
    answer: "When LeakBot detects a leak, it will notify you in the LeakBot app, by SMS and email."
  },
  {
    question: "Can I use my own plumber to repair the problem?",
    answer: "You can choose to use your own trusted plumber to locate and repair the issue. If you do this the LeakBot app will ask you to confirm the problem has been fixed and will monitor your plumbing to confirm everything is back to normal. Please be aware that we take no responsibility for 3rd party plumbing repairs. If the leak is hidden it may prove difficult for a plumber without our patented leak detection equipment to locate the problem."
  },
  {
    question: "How secure is LeakBot?",
    answer: "We take cyber security and protecting our customers data very seriously and we have added additional layers of security into our product to provide further protection. You can read our full Privacy Policy here."
  },
  {
    question: "Where can I get more information on LeakBot setup, alerts or repairs?",
    answer: "Visit <a href=\"https://leakbot.io/support/\" target=\"_blank\"  rel=\"noopener noreferrer\">https://leakbot.io/support/</a> for more information on how to setup a device, how you are notified of a leak, repairs and more."
  }
];

// depassa1 - remove this notion object and update "NotionOriginalFAQs" object name to "NotionFAQs" when notion is done being dumb

export const NotionFAQs: FAQs[] = [];

export const NotionOriginalFAQs: FAQs[] = [
  {
    question: 'How many sensors do I need?',
    answer: 'Your home has multiple sources of potential water leaks.\n\nStart with one sensor for each room with a water source. For example, a home with two bathrooms should consider five sensors(one in each bathroom, the kitchen, laundry and water heater room).\n\nWe recommend installing multiple sensors in each bathroom, as these are more prone to water leaks.',
  },
  {
    question: 'How do I set up my Notion system?',
    answer: 'First thing\'s first: go to <a href=\"https://getnotion.com/app\" target=\"_blank\"  rel=\"noopener noreferrer\">getnotion.com/app</a> on your smartphone to download the mobile app. \n\nThen, create your account and follow the instructions in the app to set up your system.',
  },
  {
    question: 'How much does Notion cost?',
    answer: 'Notion Starter Kits range from $149-199 MSRP and with Nationwide you get a discount! \n\nYou only pay for the devices (sensors, bridge, magnet). \n\nThere is no ongoing cost for the Notion base service.',
  },
  {
    question: 'How do I contact support?',
    answer: 'Visit our <a href=\"https://getnotion.com/pages/faqs-support\" target=\"_blank\" rel=\"noopener noreferrer\">Support Center</a> or reach out to our support team.\n\n(877) 668-4660 | support@getnotion.com',
  },
];

export const TingFAQs: FAQs[] = [
  {
    question: 'What is the Ting service offered by Nationwide?',
    answer: 'Nationwide wants to keep families and homes safe. As part of that mission, Nationwide has partnered with Whisker Labs, the makers of Ting, to provide access to a simple, secure, smart electrical fire monitoring device and three-year monitoring service to policyholders at no additional charge. Ting is proven to help prevent electrical fires, which are the most devastating type of house fires.',
  },
  {
    question: 'Will customers have to pay for Ting?',
    answer: 'Nationwide is offering a Ting device and three-year monitoring service to policyholders at no additional charge. After the initial three-year service period Nationwide will communicate any potential updates to the program and the policyholder can choose to pay for additional monitoring.',
  },
  {
    question: 'Does Nationwide offer a discount on my policy premium?',
    answer: 'Nationwide offers Ting to policyholders at no additional charge. A policy premium discount is not currently available.',
  },
  {
    question: 'Does using Ting affect insurance coverage or premium?',
    answer: 'No. Using Ting will not affect insurance coverage or premium.',
  },
  {
    question: 'How easy is it to install Ting?',
    answer: 'It is very easy! It typically takes, 2-5 minutes to complete the Ting installation. Ting is designed to be entirely do-it-yourself (DIY,) no matter your comfort or skill level with technology. Simply download the Ting Sensor app from the Google Play Store or App Store and the Ting Sensor app will guide you through the setup process.',
  },
  {
    question: 'What are the requirements to install Ting in my home?',
    answer: 'A smartphone and a 24/7 Wi-Fi connection with internet access are required to install Ting. Note: The Ting Sensor app is not compatible with desktops or laptop computers.',
  },
  {
    question: 'When should Ting be installed?',
    answer: 'We recommend the Ting sensor is installed within 10 days of receipt to start protecting your home.',
  },
  {
    question: 'Is Ting just a stand-alone piece of hardware?',
    answer: 'No. Ting is a smart home service with an intelligent plug-in sensor at its core. The Ting Sensor app facilitates the installation of the Ting sensor and keeps you in the know. Most importantly, the service includes 24x7 monitoring and full engagement with you in the event of a detected fire hazard.',
  },
  {
    question: 'What does the Ting Sensor measure?',
    answer: 'Ting monitors a home\'s electrical network for the main precursors to electrical fires, micro-arcs, and sparks that develop in faulty wires, loose connections, and faulty appliances or devices. These signals usually develop over weeks and months, so Ting is designed to provide advanced warnings to address these hazards before they might develop into an electrical fire. \n\nTing does not contain any other technology such as microphones, cameras, or motion detectors.',
  },
  {
    question: 'What happens if Ting identifies a hazard in my home?',
    answer: 'If Ting detects a potential fire hazard in your home, based on your preferences, you may receive: (1) an App Notification, (2) a personal text from a Ting Fire Safety Engineer, (3) a personal phone call from a Ting Fire Safety Engineer, and/or (4) a personal email. A Ting Fire Safety Engineer will walk you through details on the hazard that Ting has discovered and a plan to resolve/mitigate the hazard. If needed, a Ting-authorized contractor will be dispatched (in full coordination with you) to perform remediation services to identify and/or repair the problem. Ting will cover up to $1,000 of the service, which usually will cover all costs.',
  },
  {
    question: 'What Ting data is shared with Nationwide?',
    answer: 'Following Ting Activation, signal data from the Ting sensor is not shared with Nationwide. Nationwide receives summary counts of power quality events and any identified fire hazards from Ting. If a fire hazard is identified, a summary of the hazard is shared with Nationwide following remediation.',
  },
  {
    question: 'Who do I contact for help?',
    answer: 'Questions regarding program, data, costs, homeowners\' coverage - impact to policy, billing, eligibility requirements, premium discounts - contact nwsmarthome@nationwide.com \n\nQuestions regarding delivery/ shipment, installation challenges, Wi - Fi connectivity, app connectivity, wiring questions, responses to notifications, user data privacy - contact help@whiskerlabs.com\n\nAgent questions - contact Sales Manager, Field Product, or email nwsmarthome@nationwide.com \n\nCustomer lost email or wants a device - contact nwsmarthome@nationwide.com',
  },
];

export const PhynFAQs: FAQs[] = [
  {
    question: 'What is the Phyn service offered by Nationwide?',
    answer: "Nationwide wants to help keep families and homes safe. As part of that mission, Nationwide has partnered with Phyn, to provide access to a simple, secure, smart water shut-off device, professional installation, and a one-year in home plumbing protection plan from American Water Resources at no additional charge. With the Phyn device and app, you'll get access to and notifications about water usage insights, remote and automatic shut-off capabilities, daily plumbing checks, leak, and freeze alerts. Even when you aren't home, you'll have peace of mind knowing you can monitor conditions and take action with the click of a button.",
  },
  {
    question: 'Will I have to pay for Phyn?',
    answer: 'Nationwide is offering a Phyn device, installation, and a one-year American Water Resources home plumbing protection plan at no additional charge.',
  },
  {
    question: 'Does Nationwide offer a discount on my policy premium?',
    answer: 'Nationwide offers Phyn to policyholders at no additional charge. A policy premium discount is not available at this time.',
  },
  {
    question: 'Does using Phyn affect my insurance coverage or premium?',
    answer: 'No. Using Phyn will not affect your insurance coverage or premium.',
  },
  {
    question: 'What does the one-year plumbing protection plan include?',
    answer: 'The American Water Resources plumbing protection plan provides up to $2,000 annual plumbing infrastructure repairs. The plumbing protection plan includes unlimited service calls (up to plan limits) and repair of leaks or breaks to interior water supply lines/pipes and valves, drain lines/pipes and shutoff valves leading to fixtures or appliances. Please see the American Water Resources terms and conditions www.awrusa.com/terms for more details.',
  },
  {
    question: 'How long will it take for Phyn to be installed in my home?',
    answer: 'Installation typically takes one to two hours and depends on the location of your main water supply.',
  },
  {
    question: 'Where is Phyn installed?',
    answer: 'Phyn is installed on the main water line, right after your water meter or where the water enters your home and before your pipes split off to the various rooms and fixtures of your home.',
  },
  {
    question: 'Can I install Phyn myself?',
    answer: 'We recommend that you use a licensed plumber to install Phyn. The main water line to your home will be cut where Phyn is installed. You can also use your own licensed plumber.',
  },
  {
    question: 'Can I get my own plumber to install Phyn?',
    answer: 'Yes, you can use your own plumber to install Phyn. We have included instructions that you can give to them in the Phyn box. They must follow these instructions carefully to ensure proper installation.',
  },
  {
    question: 'What pipe size can Phyn be installed on?',
    answer: 'We recommend that Phyn be installed on pipes no larger than 1 ¼ inches.',
  },
  {
    question: 'What is the diameter of the threaded ends on Phyn?',
    answer: 'Phyn has 1” NPSM male threaded ends.',
  },
  {
    question: 'Can I install Phyn if I have a well?',
    answer: 'Absolutely! Phyn has been installed on many homes with wells.',
  },
  {
    question: 'Will Phyn turn the water off automatically if there is a leak?',
    answer: 'Once installed, Phyn will learn how you normally use water in your home. After this initial learning period, which is different for every home, it will unlock the ability to turn off your water automatically in the event of an abnormally high flow. Phyn will always send you an alert when a potential leak is detected, and will give you the power to turn off your water remotely using the free Phyn app.',
  },
  {
    question: 'Can I install Phyn if I have a fire sprinkler system?',
    answer: 'If you have a fire sprinkler system that is standalone and is on its own branch of pipes (not integrated into the same pipes that deliver water to your fixtures) then Phyn can be installed as long as it is placed after where your sprinkler line branches off. Phyn should not be installed where it will interfere with your fire sprinkler operation.',
  },
  {
    question: 'Do I need an electrical outlet near where Phyn will be installed?',
    answer: 'Yes. You will need an electrical outlet within 12 feet of where the device is installed (we recommend a GFCI equipped outlet). Phyn comes with a 12-foot power cord.',
  },
  {
    question: 'I live in (or manage) a multi-family apartment building. Will Phyn work for me?',
    answer: 'Phyn is intended for single family homes, with individual water meters. However, some multi-family dwellings that have individual plumbing lines and meters for each unit are compatible with Phyn. Typically, a PRV will also need to be installed in each unit as well. If you are interested in learning more about how you can have Phyn Plus installed in a multi-family dwelling, please call Phyn at 877.749.6792.',
  },
  {
    question: 'What happens if Phyn loses power?',
    answer: 'Phyn will remain in the state it was in when the power is lost. It will not turn off your water unless it was already in the closed position. In the event of power loss, you can use the manual shut off tool included on the mounting bracket to turn your water on and off. Please see www.phyn.com/help-center for more details.',
  },
  {
    question: 'Does Phyn monitor my irrigation system for leaks and usage?',
    answer: 'Yes. If your Phyn device is able to be installed so that it is “before” your irrigation lines, then your irrigation will be monitored for leaks and usage.',
  },
  {
    question: 'What if I have a weak Wi-Fi signal where Phyn will be installed?',
    answer: 'If your installation location is out of the range of your home\'s Wi-Fi, Phyn has a universal antenna port located on the bottom of the unit. Any external antenna can be plugged into this port to improve your Wi-Fi reception.',
  },
  {
    question: 'What Phyn data is shared with Nationwide?',
    answer: 'The data collected will include status of the device (active/inactive) and events, such as alert push notifications for water leaks. The data will be shared within Nationwide for research and analytical purposes. The information will not be shared with other third parties for marketing purposes.',
  },
  {
    question: 'Who do I contact for help?',
    answer: 'Questions regarding program, data, costs, homeowners\' coverage - impact to policy, billing, eligibility requirements, premium discounts, delivery/shipment - contact nwsmarthome@nationwide.com \n\nQuestions regarding installation challenges, Wi- Fi connectivity, app connectivity, plumbing questions, responses to notifications, user data privacy - contact www.phyn.com / help - center \n\nAgent questions - contact Sales Manager, Field Product, or email nwsmarthome@nationwide.com\n\nCustomer lost email or wants a device - contact nwsmarthome@nationwide.com',
  },
];
