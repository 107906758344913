import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { UserProfile } from '../../types/userProfile';
import { Device } from '../../types/device';

@Component({
  selector: 'app-device-recommendations',
  templateUrl: './device-recommendations.component.html',
  styleUrls: ['./device-recommendations.component.less']
})
export class DeviceRecommendationsComponent {

  recommendedDevices: Device[] | undefined = [];
  profile: UserProfile | undefined;

  constructor(private router: Router, private profileService: ProfileService) {
    this.profileService.profile$.subscribe((profile) => {
      this.profile = profile;
      this.recommendedDevices = this.profile.devices;
    });
  }

  ngOnInit() {

  }
}
