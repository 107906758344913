import { UserPrivilege } from '../constants';
import { Device } from './device';
import { PolicyType } from './policy-info';

export class Customer {
  firstName: string = '';
  middleName?: string = '';
  lastName: string = '';
  email: string = '';
  phone: string = '';
  addresses: Address[] = [];
  isExistingMember: boolean = false;

  constructor() { }
}

export class Address {
  city: string = '';
  state: string = '';
  street: string = '';
  zipCode: string = '';
  isPolicyAddress: boolean = false;
  selected: boolean = false;

  constructor() { }
}

export type Survey = {
  question: string;
  answers: Answer[];
}

export type Answer = {
  answer: string;
  selected: boolean;
}

export class UserProfile {
  privilegeLevel?: UserPrivilege = UserPrivilege.Unauthenticated;
  currentDevice: string = '';
  policyNumber: string = '';
  policyType?: PolicyType;
  dataCollectionId: string = '';
  customer: Customer = new Customer();
  devices: Device[] = [];
  survey: Survey[] | undefined = [];
  constructor() { }
}

export class UnidentifiedUser {
  person: any = { firstName: '' };
  policyHolderId: number = 0;
  policyHolderType?: PolicyType;

  constructor() { }
}
