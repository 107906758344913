import { QA } from "./deviceInfo";

export const SmartHomeStaticInfo = {
    faqs: [
        {
            question: "Is this the Smart Home Experience FAQs?",
            answer: "Leak Bot"
        },
        {
            question: "What year is it?",
            answer: "2023"
        },
        {
            question: "How many questions should I write as examples?",
            answer: "I guess it's going to be three."
        }
    ],
}

export type SmartHomeInfo = {
    faqs: QA[];
}