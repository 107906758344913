import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaterDevicesComponent } from './water-devices.component';



@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    WaterDevicesComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    WaterDevicesComponent
  ]
})
export class WaterDevicesModule { }
