import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { firstValueFrom, lastValueFrom } from 'rxjs';
import { PolicyType } from '../../types/policy-info';
import { ApiService } from '../api/api.service';
import { ConfigLoaderService } from '../config-loader/config-loader.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyDetailsService {
  baseUrl: string;
  plsEnv: string = 'pls03';

  constructor(private api: ApiService, private http: HttpClient, private config: ConfigLoaderService) {
    this.baseUrl = '';
    this.config.appConfig$.subscribe((config) => {
      const environ = config?.secrets?.smartHomeMarket ? config.secrets.smartHomeMarket.env : 'dev';
      if (environ === 'prod') this.plsEnv = '';
    });
  }

  async getPolicyDetails(): Promise<any | undefined> {
    return await this.processRequest(
      'api/program/',
      (response: any) => {
        console.log('getPolicyDetails service call succeeded');
        return response;
      },
      (response: any) => {
        console.log('getPolicyDetails service call failed');
        return response;
      },
    );
  }

  async processRequest(
    path: string,
    onSuccess: (response: any) => {},
    onError: (error: any) => {}
  ): Promise<any | undefined> {
    const options = {
      headers: new HttpHeaders({}),
      params: { id: '' }
    };

    return lastValueFrom(this.http
      .get(this.baseUrl + path, options))
      .then(onSuccess)
      .catch(onError);
  }

  async getPolicyOwnerName(policyNumber: string, policyType: PolicyType): Promise<any> {
    let headers: any = {
      "Authorization": `Bearer ${sessionStorage.getItem('access_token')}`,
    };
    if (this.plsEnv !== '') headers["X-NW-Target-ENV"] = this.plsEnv;
    const options = {
      headers: new HttpHeaders(headers)
    }
    return await firstValueFrom(this.api.apigeePolicyCenterGet(`${policyType}-policies/v1/policies/${policyNumber}/policy-holders`, options)).catch((err) => { throw err });
  }
}
