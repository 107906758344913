import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SmartHomeHelpRoutingModule } from './smarthome-help-routing.module';
import { SmartHomeHelpComponent } from './smarthome-help.component';
import { HeaderComponent } from 'src/app/core/components/header/header.component';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    SmartHomeHelpComponent,
  ],
  imports: [
    CommonModule,
    SmartHomeHelpRoutingModule,
  ]
})
export class SmartHomeHelpModule { }
