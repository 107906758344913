import { Component } from '@angular/core';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent {
  dataCollectionId: string = "";

  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
    this.profileService.profile$.subscribe((profile) => {
      this.dataCollectionId = profile.dataCollectionId;
    })
  }
}
