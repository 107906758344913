import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import Bolt from '@nationwide-bolt/bundle-core';

Bolt.initialize().then(() => {
  // Now load the the instance
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
})