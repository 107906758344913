import { Component, Input } from '@angular/core';

import { DeviceInfo, DeviceStaticInfo, HowItWorksCardInfo, VideoInfo } from '../../types/deviceInfo';
import { VendorName } from '../../types/device';

@Component({
  selector: 'app-how-device-works',
  templateUrl: './how-device-works.component.html',
  styleUrls: ['./how-device-works.component.less']
})
export class HowDeviceWorksComponent {


  // Inputs/Outputs
  @Input() device: VendorName = VendorName.LEAKBOT;
  @Input() videoInfo: VideoInfo | undefined;
  @Input() cardInfo: HowItWorksCardInfo[] = [];

  constructor() { }

  ngOnInit() {
  }
}
