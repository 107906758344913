<div class="mobile-updates-outline mt-2 mb-4">
    <div class="bolt-container">
        <div class="bolt-row">
            <div class="bolt-col">
                <div class="mobile-updates-header p-0 mb-5 mt-5 font-size-md">Want text updates about your order?</div>
                <div class="mb-5 p-0 font-size-normal">Enter your mobile phone number below.</div>
            </div>
        </div>
    </div>

    <div class="bolt-container mb-5">
        <div class="bolt-row bg-slate-50 items-end">
            <div class="bolt-col">
                <bolt-input-control>
                    <label for="INPUT_ID">
                        <span class="font-size-normal">Mobile phone number</span>
                    </label>
                    <bolt-input-facade>
                        <bolt-icon color="black" name="phone"></bolt-icon>
                        <input id="INPUT_ID" type="text" />
                    </bolt-input-facade>
                </bolt-input-control>
            </div>
        </div>
    </div>

    <div class="bolt-container mb-5">
        <div class="bolt-row">
            <div class="bolt-col">
                <bolt-button size="lg" type="primary" width="full" class="submit-survey">Submit</bolt-button>
            </div>
        </div>
    </div>

    <div class="bolt-container mb-5">
        <div class="bolt-row">
            <div class="bolt-col mobile-updates-discalimer">
                By providing your mobile phone number and selecting “Submit” you are consenting to receive automated
                improtant
                delivery and installation updates via Text/SMS from [vendor].
            </div>
        </div>
    </div>
</div>